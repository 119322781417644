<!-- colworx-ak: added ngStyle-->
<div
    class="sticky-top d-flex flex-row justify-content-between px-0 overflow-auto webkit-sm"
    [ngStyle]="{
        'background': showBackground
          ? '#f8f9fc' 
          : ''
      }"
    style="
        height: calc(100vh - 290px);
        border-radius: 12px 0 0 12px;
        border: 1px solid rgba(22, 44, 94, 0.15);
        z-index: 599;
    "
>
    <div class="col-12 px-0">
        <!-- Collapse -->
        <div class="bg-white">
            <button
                type="button"
                class="m-0 p-3 btn d-flex flex-row justify-content-around bg-white"
                style="
                    box-shadow: none !important;
                    border: none !important;
                    outline: none !important;
                    border-bottom: 1px solid rgba(22, 44, 94, 0.15) !important;
                    width: 100%;
                    border-radius: 0;
                "
                (click)="closed.emit(false)"
            >
                <span class="body-medium-bold text-secondary" class="text-secondary body-medium-bold">{{
                    header
                }}</span>
                <!-- <span class="material-symbols-outlined solid ml-auto" *ngIf="isCollapsed"> expand_less </span>
                <span class="material-symbols-outlined solid ml-auto" *ngIf="!isCollapsed"> expand_more </span> -->
                <span class="material-symbols-outlined solid ml-auto"> clear </span>
            </button>
            <div [class]="isClass ? 'px-3 py-4' : ''" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                <!-- Single Item -->
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
