import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User, UserApiResponse, Right, Resource, Role } from '@app/models';
import { AuthService } from '.';
import { finalize, map } from 'rxjs/operators';

const baseUrl = `${environment.apiUrl}/user`;

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    getAll(orgId: string, facilityId, tab: string) {
        return this.http.get<UserApiResponse>(`${baseUrl}/${orgId}/${facilityId}?userState=${tab}`);
    }
    getUserRights() {
        return this.http.get<any>(`${environment.apiUrl}/rights/list`);
    }
    getRolesByOrganization(organizationId: string, excludeRoles='') {
        return this.http.get<any>(`${environment.apiUrl}/role/list/get-by-organization/${organizationId}?excludeRoles=${excludeRoles}`);
    }
    getActiveRoles(organizationId: string) {
        return this.http.get<any>(`${environment.apiUrl}/role/get-roles/organization/${organizationId}?display=true`);
    }
    getAllRoles(organizationId: string) {
        return this.http.get<any>(`${environment.apiUrl}/role/get-roles/organization/${organizationId}`);
    }
    // colworx-ak:getRolesByUserId
    getRolesByUserId(userId: string) {
        return this.http.get<any>(`${baseUrl}/${userId}/get-roles`);
    }

    resendInvite(id: string) {
        return this.http.get<any>(`${environment.apiUrl}/user/resend-invite/${id}`, {});
    }

    cancelInvite(id: string) {
        return this.http.get<any>(`${environment.apiUrl}/user/cancel-invite/${id}`, {});
    }
    getRoleById(id: string) {
        return this.http.get<any>(`${environment.apiUrl}/role/${id}`);
    }
    deleteRole(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}/role/${id}`);
    }
    createRole(role: Role) {
        return this.http.post<any>(`${environment.apiUrl}/role`, role);
    }
    getNumberOfUsersByRole(roleId: string, orgId: string) {
        return this.http.get<any>(`${baseUrl}/role/total-count?roleId=${roleId}&orgId=${orgId}`);
    }
    getRightById(id) {
        return this.http.get<any>(`${environment.apiUrl}/rights/${id}`);
    }
    deleteRight(id) {
        return this.http.delete<any>(`${environment.apiUrl}/rights/${id}`);
    }
    getResources() {
        return this.http.get<any>(`${environment.apiUrl}/resource/list`);
    }
    getResourceById(id) {
        return this.http.get<any>(`${environment.apiUrl}/resource/${id}`);
    }
    deleteResource(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}/resource/${id}`);
    }
    createRight(right: Right) {
        return this.http.post<any>(`${environment.apiUrl}/rights`, right);
    }
    createResource(resource: Resource) {
        return this.http.post<any>(`${environment.apiUrl}/resource`, resource);
    }
    activateOrDeactivateUser(body: any) {
        return this.http.post<any>(`${baseUrl}/activate-deactivate`, body);
    }
    getById(id: string) {
        return this.http.get<User>(`${baseUrl}/${id}`);
    }

    getCurrentUser(id: string) {
        return this.http.get<User>(`${baseUrl}/profile/${id}`);
    }

    delete(id: string, role: string) {
        return this.http.delete(`${baseUrl}/${id}`, { body: { role } }).pipe(
            finalize(() => {
                // auto logout if the logged in account was deleted
                if (id === this.authService.accountValue.user.id) this.authService.logout();
            })
        );
    }

    changePassword(id: string, password: string) {
        return this.http.put(`${baseUrl}/profile/change-password/${id}`, { password });
    }

    updatePassword(id: string, password: string) {
        return this.http.put(`${baseUrl}/change-password/${id}`, { password });
    }

    forceLogout(id: string) {
        return this.http.get(`${baseUrl}/log-out/${id}`);
    }
    //colworx-ak:add physician updatePasswordOldConfirm
    updatePasswordOldConfirm(id: string, body: any) {
        return this.http.put(`${environment.apiUrl}/physician/change-password/${id}`, body);
    }
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params).pipe(
            map((account: any) => {
                // update the current account if it was updated
                if (account.id === this.authService.accountValue.user.id) {
                    // publish updated account to subscribers
                    account = { ...this.authService.accountValue, ...account };
                    this.authService.accountSubject.next(account);
                }
                return account;
            })
        );
    }

    updateProfile(id, params) {
        return this.http.put(`${baseUrl}/profile/${id}`, params).pipe(
            map((account: any) => {
                // update the current account if it was updated
                if (account.id === this.authService.accountValue.user.id) {
                    // publish updated account to subscribers
                    account = { ...this.authService.accountValue, ...account };
                    this.authService.accountSubject.next(account);
                }
                return account;
            })
        );
    }
    updateRight(id: string, params: Right) {
        return this.http.put(`${environment.apiUrl}/rights/${id}`, params).pipe(
            map((right: any) => {
                return right;
            })
        );
    }
    updateResource(id: string, params: Resource) {
        return this.http.put(`${environment.apiUrl}/resource/${id}`, params).pipe(
            map((resource: any) => {
                return resource;
            })
        );
    }
    updateRole(id: string, params: Role) {
        return this.http.put(`${environment.apiUrl}/role/${id}`, params).pipe(
            map((role: any) => {
                return role;
            })
        );
    }
}
