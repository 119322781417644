export const ROUTER_UTILS = {
    config: {
        base: {
            root: '',
            dorDashboard: 'operations-dashboard',
            therapistDashboard: 'therapist-dashboard',
               //colworx-sk
               physicianDashboard: 'physician-dashboard',
               //colworx-sk
            home: 'home',
            therapistCaseload: 'therapist-caseload',
            therapistPendingDoc: 'therapist-pending-documents',
            therapistCoSignPendingDoc: 'therapist-co-sign',
            dorPendingDoc: 'dor-pending-documents',
            dorCoSignPendingDoc: 'dor-co-sign',
            dorCoTreatment: 'dor-co-treatment',
            minutesByPatient: 'minutes-by-patient',
            dorUpcomingDoc: 'dor-upcoming-documents',
            therapistCertifications: 'therapist-certifications',
            dorCertifications: 'dor-certifications',
            dorProductivity: 'dor-productivity',
        },
        account: {
            root: 'account',
            logIn: 'login',
            register: 'register',
            forgotPassword: 'forgot-password',
            verifyEmail: 'verify-email',
            resetPassword: 'password-reset/:token',
        },
        notification: {
            root: 'notification',
        },
        timeTracking: {
            root: 'time-tracking',
            dorView: 'dor-view',
        },
        admin: {
            root: 'admin',
            accounts: {
                root: 'accounts',
                home: '',
                addUser: 'add-user',
                editUser: 'edit-user',
                viewUser: 'view-user',
                changePassword: 'change-password',
            },
            rights: 'rights',
            resources: 'resources',
            roles: 'roles',
            recipients: {
                home: 'recipients',
                addRecipient: 'add-recipient',
            },
            userRoles: 'user-roles',
            integration: {
                root: 'integration',
                home: '',
                addIntegration: 'add-integration',
            },
            payors: {
                root: 'payors',
                home: '',
                addPayor: 'add-payor',
                editPayor: 'edit-payor',
                addContract: 'add-contract',
                addContractTemplate: 'add-contract-template',
                details: 'details',
            },
            facility: {
                root: 'facility',
                home: '',
                addFacility: 'new',
                viewFacility: 'view',
                editFacility: 'edit',
                details: 'details',
                addPayors: 'add-payors',
                addRecipients: 'add-recipient-portal',
            },
            organization: {
                root: 'organization',
                home: '',
                addOrganization: 'new',
                viewOrganization: 'view',
                editOrganization: 'edit',
                organizationDetails: 'organization-details',
                editNpc: 'editNpc'
            },
        },

        profile: {
            root: 'profile',
            home: '',
            update: 'update',
        },
        addPatients: {
            root: 'admission',
            edit: 'edit',
            editAdmission: '/admission/selected',

            home: '',
            personalInformation: 'personal-information',
            facilityAdmission: 'facility-admission',

            filesAndDocuments: 'files-and-documents',
        },
        therapyAdmission: {
            root: 'therapy-admission',
            editTherapyAdmission: '/therapy-admission/edit',
            home: '',
            add: {
                root: 'therapy-admission/add',
                home: '',
            },
            edit: {
                root: 'therapy-admission/edit',
                home: '',
            },
            show: {
                root: 'therapy-admission/show',
                home: '',
            },
        },
        payorInformation: {
            home: '',
            root: 'payor-information',
            insurance: 'insurance-coverage',
            authorization: {
                add: {
                    root: 'payor-authorization/add',
                },
                edit: {
                    root: 'payor-authorization/edit',
                },
            },
            insuranceCoverage: {
                root: 'insurance-coverage',
                home: '',
                add: {
                    root: 'insurance-coverage/add',
                    home: '',
                },
                edit: {
                    root: 'insurance-coverage/edit',
                },
            },
        },
        patients: {
            root: 'patients',
            home: '',
            patientList: 'list',
            patientBoard: 'board',

            pcc: {
                root: 'pcc',
                home: '',
                tabs: {
                    root: 'tabs',
                    clinicalSummary: 'clinical-summary',
                    documentation: {
                        home: '',
                        root: 'documentation',
                        evalDoc: {
                            home: '',
                            root: 'evaluation-document',
                            medicalReview: 'medical-review',
                            backgroundAssessment: 'background-assessment',
                            patientAssessment: {
                                home: '',
                                root: 'patient-assessment',
                                functionalDeficits: 'functional-deficits',
                                standardizedTests: 'standardized-tests',
                                impairments: 'impairments',
                            },
                            medicalNecessity: 'medical-necessity',
                            planOfCare: 'plan-of-care',
                            evaluationType: 'evaluation-type',
                        },
                        dailyNoteDoc: {
                            home: '',
                            root: 'daily-note-document',
                        },
                        reCertificationDoc: {
                            home: '',
                            root: 're-certification-document',
                        },
                        progressNoteDoc: {
                            home: '',
                            root: 'progress-note-document',
                        },
                        upocNoteDoc: {
                            home: '',
                            root: 'upoc-note-document',
                        },
                        dischargeNote: {
                            home: '',
                            root: 'discharge-note-document',
                        },
                        addendumNote: {
                            home: '',
                            root: 'addendum-note-document',
                        },
                    },
                    outcomes: 'outcomes',
                    visitHistory: 'visit-history',
                    PDPM: 'pdpm',
                    MDS: 'mds',
                    orders: 'orders',
                    admissionHistory: 'admission-history',
                },
                // * new routes for pcc
                therapyCase: {
                    root: 'therapy-case',
                    therapyAdmission: 'therapy-admission',
                },
                therapyCaseDisciplines: {
                    root: 'therapy-case',
                    home: '',
                },
                payorInformation: {
                    home: '',
                    root: 'payor-information',
                },
                filesAndDocuments: {
                    home: '',
                    root: 'files-and-documents',
                },
                pdpm: {
                    home: '',
                    root: 'pdpm',
                    // board: 'board',
                    // details: 'details',
                },
                mds: {
                    home: '',
                    root: 'mds',
                    // board: 'board',
                    // details: 'details',
                },
                v2: {
                    root: 'v2',
                    home: '',
                    therapyCase: {
                        root: 'therapy-case',
                        therapyAdmission: 'therapy-admission',
                    },
                    therapyCaseDisciplines: {
                        root: 'therapy-case',
                        home: '',
                    },
                    payorInformation: {
                        home: '',
                        root: 'payor-information',
                    },
                    filesAndDocuments: {
                        home: '',
                        root: 'files-and-documents',
                    },
                    pdpm: {
                        home: '',
                        root: 'pdpm',
                        // board: 'board',
                        // details: 'details',
                    },
                    mds: {
                        home: '',
                        root: 'mds',
                        // board: 'board',
                        // details: 'details',
                    },
                },
            },
            pdpm: {
                root: 'pdpm',
                home: '',
                board: 'board',
                details: 'details',
            },
        },
        documentationViewMode: {
            root: 'viewMode',
            home: '',
            dailyNoteDoc: {
                home: '',
                root: 'daily-note-view',
            },
            progressNoteDoc: {
                home: '',
                root: 'progress-note-view',
            },
            updatePlanOfCare: {
                home: '',
                root: 'update-plan-of-care-view',
            },
            reCertificationDoc: {
                home: '',
                root: 're-certification-document',
            },
            dischargeNote: {
                home: '',
                root: 'discharge-note-document',
            },
            evalDoc: {
                home: '',
                root: 'evaluation-document',
            },
            addendum: {
                home: '',
                root: 'addendum-document',
            },
        },
        scheduling: {
            root: 'scheduling',
            home: '',
            planner: 'planner',
            scheduler: 'scheduler',
            schedulerV2: 'scheduler-v2',
            whiteBoard: 'white-board',
            patient: 'patient',
            therapist: 'therapist',
        },
        labor: {
            root: 'labor',
            laborManagementLayout: 'labor-management',
        },
        reports: {
            root: 'reports',
            serviceLog: 'service-log',
            billingExport: 'billing-export',
            standardBillingExport: 'standard-billing-export',
            tripleCheck: 'triple-check',
            endOfMonthReport: 'EOM-Report',
            mopReport: 'mbp-report',
        },
        billing: {
            root: 'billing',
            home: '',
            eom: 'eom-bill',
            invoice: 'invoice',
            createInvoiceProfile: 'create-invoice-profile',
            manageRecipient: 'manage-recipient',
            billingDetails: 'billing-detail',
        },
          // colworx-ak add physician user management route name
          physicianAccountsAndDocument: {
            root: 'physician',
            staff: 'staff',
            account: 'user-management',
            personalInformation: 'personal-info',
            facilityAdd: 'assign-facility',
            pocDoc: 'poc-documents',
            addUser: 'add-user',
            editUser: 'edit-user',
            viewUser: 'view-user',
            changePassword: 'change-password',
        },
        //
        learning: {
            root: 'learning',
            home: '',
            videos: '/videos',
        },
        settings: {
            root: 'settings',
            home: '',
        },
        help: {
            root: 'help',
            home: '',
        },
        print: {
            root: 'print',
            home: '',
        },
        discharge: {
            root: 'discharge',
            home: '',
        },
    },
};
