// colworx-ak start
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PpDocumentsService {
    constructor(private http: HttpClient) {}

    private dataSource = new BehaviorSubject<any>(this.getDataFromStorage());

    currentData = this.dataSource.asObservable();

    updateData(data: any) {
        this.dataSource.next(data);
        localStorage.setItem('patientData', JSON.stringify(data));
    }

    private getDataFromStorage() {
        const data = localStorage.getItem('patientData');
        return data ? JSON.parse(data) : null;
    }
}
