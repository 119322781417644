import { ColDef } from 'ag-grid-community';
import { DropdownPillComponent } from '../../../data-grid/cell-renderers/dropdown-pill/dropdown-pill-cell-renderer.component';
import { ColoredCellRendererComponent, SignaturesCellRendererComponent } from '@app/data-grid/cell-renderers';
import { environment } from '@environments/environment';
import { Button } from '@app/shared/toggle/toggle.component';

export function getEOMBillingHeaders(canEdit): ColDef[] {
    return [
        {
            headerName: 'Name',
            resizable: true,
            sortable: false,
            valueGetter: (v) => (v?.data?.facility ? v.data.facility : ''),
        },
        {
            headerName: 'Type',
            resizable: true,
            sortable: false,
            valueGetter: (v) => (v?.data?.type ? v.data.type : ''),
        },
        {
            headerName: 'Errors',
            resizable: true,
            sortable: true,
            sort: 'desc',
            sortingOrder: ['asc', 'desc'],
            cellRenderer: ColoredCellRendererComponent,
            cellRendererParams: { color: 'error' },
            valueGetter: (v) => (v?.data ? v?.data.errors ? v.data.errors : '0' : ''),
        },
        {
            headerName: 'Warnings',
            resizable: true,
            sortable: true,
            sort: 'asc',
            sortingOrder: ['asc', 'desc'],
            cellRenderer: ColoredCellRendererComponent,
            cellRendererParams: { color: 'warning' },
            valueGetter: (v) => (v?.data ? v.data.warnings ? v.data.warnings : '0' : ''),
        },
        {
            headerName: 'EOM Status',
            resizable: true,
            sortable: false,
            colId: 'eomStatus',
            cellRenderer: DropdownPillComponent,
            cellRendererParams: {
                readonly: !canEdit
            },
            valueGetter: (v) => (v?.data?.eomStatus ? v.data.eomStatus : null),
        },
        {
            headerName: 'Invoicing Status',
            resizable: true,
            sortable: false,
            colId: 'invoicingStatus',
            cellRenderer: DropdownPillComponent,
            valueGetter: (v) => (v?.data?.invoiceStatus ? v.data.invoiceStatus : null),
        },
    ];
}

export function getEOMBillingDetailHeaders(headerType: string): ColDef[] {
    if (headerType === TABS.PDPM) {
        return [
            {
                headerName: 'Patient Name',
                resizable: true,
                sortable: false,
                valueGetter: (v) => (v?.data?.patient ? v.data.patient : 'N/A'),
            },
            {
                headerName: 'MRN',
                resizable: true,
                sortable: false,
                valueGetter: (v) => (v?.data?.mrn ? v.data.mrn : 'N/A'),
            },
            {
                headerName: 'Payor',
                resizable: true,
                sortable: false,
                valueGetter: (v) => (v?.data?.payor ? v.data.payor : 'N/A'),
            },
            // {
            //     headerName: 'Discipline',
            //     resizable: true,
            //     sortable: false,
            //     valueGetter: (v) => (v?.data?.discipline ? v.data.discipline : 'N/A'),
            // },
            {
                headerName: 'Error Type',
                resizable: true,
                sortable: false,
                cellRenderer: ColoredCellRendererComponent,
                cellRendererParams: (v) => (v.data.errorType ? { color: 'error' } : { color: 'disabled' }),
                valueGetter: (v) => (v?.data?.errorType ? v.data.errorType : 'N/A'),
            },
            {
                headerName: 'Admit Date',
                resizable: true,
                sortable: true,
                valueGetter: (v) => (v?.data?.admitDate ? v.data.admitDate : 'N/A'),
            },
        ];
    } else if (headerType === TABS.PENDING_DOCUMENTS) {
        return [
            {
                headerName: 'Patient Name',
                resizable: true,
                sortable: false,
                valueGetter: (v) => (v?.data?.patient ? v.data.patient : 'N/A'),
            },
            {
                headerName: 'Payor',
                resizable: true,
                sortable: false,
                valueGetter: (v) => (v?.data?.payor ? v.data.payor : 'N/A'),
            },
            {
                headerName: 'Doc Type',
                resizable: true,
                sortable: false,
                valueGetter: (v) => (v?.data?.docType ? v.data.docType : 'N/A'),
            },
            {
                headerName: 'Error Type',
                resizable: true,
                sortable: false,
                cellRenderer: ColoredCellRendererComponent,
                cellRendererParams: (v) => (v.data.errorType ? { color: 'error' } : { color: 'disabled' }),
                valueGetter: (v) => (v?.data?.errorType ? v.data.errorType : 'N/A'),
            },
            {
                headerName: 'Therapists',
                resizable: true,
                sortable: false,
                autoHeight: true,
                cellRenderer: SignaturesCellRendererComponent,
                cellRendererParams: (v) => (v.data.therapist ? v.data.therapist : v.data.therapist),
                valueGetter: (v) => (v?.data?.therapist ? v.data.therapist : v.data.therapist),
            },
            {
                headerName: 'Due Date',
                resizable: true,
                sortable: true,
                valueGetter: (v) => (v?.data?.dueDate ? v.data.dueDate : 'N/A'),
            },
        ];
    } else if (headerType === TABS.UNAUTH_VISITS) {
        return [
            {
                headerName: 'Patient Name',
                resizable: true,
                sortable: false,
                valueGetter: (v) => (v?.data?.patient ? v.data.patient : 'N/A'),
            },
            {
                headerName: 'Payor',
                resizable: true,
                sortable: false,
                valueGetter: (v) => (v?.data?.payor ? v.data.payor : 'N/A'),
            },
            {
                headerName: 'Discipline',
                resizable: true,
                sortable: false,
                valueGetter: (v) => (v?.data?.discipline ? v.data.discipline : 'N/A'),
            },
            {
                // eslint-disable-next-line spellcheck/spell-checker
                headerName: 'No of unauth visits',
                resizable: true,
                sortable: false,
                valueGetter: (v) => (v?.data?.visits ? v.data.visits : 'N/A'),
            },
            {
                headerName: 'AuthorizationStatus',
                resizable: true,
                sortable: false,
                cellRenderer: ColoredCellRendererComponent,
                cellRendererParams: () => ({ color: 'error' }),
                valueGetter: (v) => (v?.data?.authStatus ? v.data.authStatus : 'N/A'),
            },
            {
                headerName: 'Auth expiry Date',
                resizable: true,
                sortable: true,
                valueGetter: (v) => (v?.data?.authExpiryDate ? v.data.authExpiryDate : 'N/A'),
            },
        ];
    }
}

export const TABS = {
    PENDING_DOCUMENTS: 'Pending Documents',
    UNAUTH_VISITS: 'Unauthorized Visits',
    PDPM: 'PDPM',
    ERRORS: 'Errors',
    WARNINGS: 'Warnings',
};

export const ERROR_TOGGLE_BUTTONS: Button[] = [
    {
        text: TABS.PDPM,
        isSelected: true,
        count: 0,
    },
    {
        text: TABS.PENDING_DOCUMENTS,
        isSelected: false,
        count: 0,
    },
    {
        text: TABS.UNAUTH_VISITS,
        isSelected: false,
        count: 0,
    },
];

export const WARNING_TOGGLE_BUTTONS: Button[] = [
    {
        text: TABS.PENDING_DOCUMENTS,
        isSelected: false,
        count: 0,
    },
    {
        text: TABS.UNAUTH_VISITS,
        isSelected: false,
        count: 0,
    },
];

export const TAB_BUTTONS: Button[] = [
    {
        text: TABS.ERRORS,
        isSelected: true,
        count: 0,
    },
    {
        text: TABS.WARNINGS,
        isSelected: false,
        count: 0,
    },
];

export const FACILITY_NAV_ITEMS = {
    PAYORS: 'Payors',
    FACILITY_DETAILS: 'Facility Details',
    OTHER_SERVICES: 'Timecard Activities',
    CONTRACT_TEMPLATES: 'Contract Templates',
    RECIPIENTS: "Recipients"
}

export const BASE_URL = `${environment.apiUrl}`;

export const END_POINTS = {
    EOM_STATUS: `${BASE_URL}/billing/eom-bill`,
    UPDATE_STATUS: `${BASE_URL}/billing/eom-bill/update-status`,
};

export const createUrl = (patientId: string, landOn: string, facilityId?: string, stamp?: number): string => {
    let route: string;
    switch (landOn) {
        case TABS.PDPM:
            route = `patients/pdpm/details/${patientId}/${facilityId}/${stamp}`;
            break;
        case TABS.UNAUTH_VISITS:
            route = `pcc/payor-information?patient=${patientId}&facilityAd=${facilityId}&isFromEOM=true`;
            break;
    }
    return route;
};

export const SKELETON_CSS = {
    TABS_CELL: {
        width: '20%',
        height: '3.4em',
        margin: '0 2em 0.1em 2em',
    },
    TOGGLE_CELL: {
        width: '40%',
        height: '3.4em',
        margin: '0 2em 0.1em 31.5vw',
    },
    TABLE_CELL: {
        width: '96%',
        height: '3.4em',
        margin: '0 2em 0.1em 2em',
    },
};

export const PAYOR_CONSTANTS = {
   ADD_NEW_PAYOR: 'Add new Payor',
   PRINT:'Print',
   EXPORT:'Export'
};
