import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { BehaviorSubject, tap, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { counties, states } from '@app/helpers/States-Counties/index';
import { UserApiResponse } from '@app/models';

const baseUrl = `${environment.apiUrl}`;
@Injectable({
    providedIn: 'root',
})
export class FacilityManagementService {
    public QuickLinkNotifySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public QuickLinkClickedSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private facilities$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    private currentFacility$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    private allFacilities: any[] = [];
    private currentFacility: any;
    private currentFacilityRecipients = [];
    constructor(private http: HttpClient, private formBuilder: FormBuilder) {}
    hasOID(facility: string) {
        return this.http.get<UserApiResponse>(`${baseUrl}/facility/${facility}/hasOID`);
    }
    OIDExists(facilityOID: string, exclude?: string[]) {
        let url = `${baseUrl}/facility/isDuplicateFacilityOID/${facilityOID}`;
        if (exclude)
            exclude.forEach((item, index) => {
                if (index === 0) url = `${url}?exclude=${item}`;
                else url = `${url}&exclude=${item}`;
            });
        return this.http.get<UserApiResponse>(url);
    }
    updateRecipientInFacility(recipientObject: any) {
        return this.http.post(`${baseUrl}/facility/update-recipients-list`, recipientObject, {
            withCredentials: true,
        });
    }
    addRemoveRecipientInFacility(recipientObject: any) {
        return this.http.post(`${baseUrl}/facility/add-remove-recipient`, recipientObject, {
            withCredentials: true,
        });
    }
    getLocalityByZipCode(zipCode: string) {
        return this.http.get(`${baseUrl}/locality/${zipCode}`, {
            withCredentials: true,
        });
    }
    // eslint-disable-next-line spellcheck/spell-checker
    isValidOID(oid: string) {
        // eslint-disable-next-line spellcheck/spell-checker
        return this.http.post(`https://integration.innovahealth.app/api/v1/matrix-care`, JSON.stringify({ oid }), {
            headers: { 'Content-Type': 'text/xml' },
        });
    }
    // eslint-disable-next-line spellcheck/spell-checker
    getLcdCols(): ColDef[] {
        return [
            //{ headerName: 'No.', field: 'no', width: 60, valueGetter: 'node.rowIndex + 1' },
            { headerName: 'MAC Name', field: 'name', wrapText: true, autoHeight: true },
            { headerName: 'MAC Address', field: 'address', wrapText: true, autoHeight: true },
            { headerName: 'MAC City', field: 'city', wrapText: true, autoHeight: true },
            { headerName: 'MAC State', field: 'state', wrapText: true, autoHeight: true },
            { headerName: 'Zip Code', field: 'zipCode', wrapText: true, autoHeight: true },
        ];
    }
    getQuickLinks() {
        return [
            // eslint-disable-next-line spellcheck/spell-checker
            { el: 'onBoarding', label: 'Facility Onboarding' },
            { el: 'mailAddress', label: 'Mailing Address & Contact' },
            { el: 'billAddress', label: 'Billing Address & Contact' },
            { el: 'admission', label: 'Admission' },
            { el: 'customerInfo', label: 'Customer information' },
            { el: 'npi', label: 'Facility NPI' },
            { el: 'medCare', label: 'Medicare and Medicaid Information' },
        ];
    }

    facilityOnBoardingForm(): FormGroup {
        return this.formBuilder.group({
            facilityName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
            alternateName: ['', [Validators.minLength(2), Validators.maxLength(30)]],
            facilityType: ['', [Validators.required]],
            facilityAreaNumber: ['', [Validators.minLength(2), Validators.maxLength(40)]],
            status: ['', [Validators.required]],
            PBJType: ['', Validators.required],
            PBJID: ['', Validators.required],
            facilityNumber: ['', [Validators.required]],
            organization: [{ value: '', disabled: true }, [Validators.required]],
            timezone: ['', [Validators.required]],
            isBothAddressSame: [false],
            mailingAddress: this.formBuilder.group({
                address: ['', [Validators.required]],
                city: ['', [Validators.minLength(3), Validators.maxLength(20)]],
                county: [''],
                zipCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
                state: ['', [Validators.required]],
                carrier: ['', [Validators.required]],
                locality: ['', [Validators.required]],
                urbanOrRural: [''],
                phoneNo: ['', [Validators.required]],
                fax: [''],
                //timezone: ['', [Validators.required]],
            }),
            billingAddress: this.formBuilder.group({
                address: ['', [Validators.required]],
                city: ['', [Validators.minLength(3), Validators.maxLength(20)]],
                county: [''],
                zipCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
                state: ['', [Validators.required]],
                carrier: ['', [Validators.required]],
                locality: ['', [Validators.required]],
                urbanOrRural: [''],
                phoneNo: ['', [Validators.required]],
                fax: [''],
                //timezone: ['', [Validators.required]],
            }),
            admissionType: ['', [Validators.required]],
            coSignSettings: [''],
            customerInformation: this.formBuilder.group({
                CMSFacilityID: ['', [Validators.required]],
                customerStartDate: ['', [Validators.required]],
                customerNo: ['', [Validators.required]],
            }),
            // MACProvider: this.formBuilder.group({
            //     name: [''],
            //     address: [''],
            //     city: [''],
            //     state: [''],
            //     zipCode: [''],
            // }),
            facilityNPI: this.formBuilder.group({
                serviceFacilityNPI: ['', [Validators.required]],
                billingNPI: ['', [Validators.required]],
                alternateFacilityNPI: ['', [Validators.required]],
            }),
            facilityOID: ['', []],
            medicareProviderNo: ['', [Validators.required]],
            medicaidProviderNo: ['', [Validators.required]],
            eomStatus: [{ month: '', status: '' }],
            invoiceStatus: [{ month: '', status: '' }],
        });
    }

    getUSAStates() {
        return states;
    }
    isOfCurrentOrganization(id: string) {
        return this.allFacilities.find((facility) => facility.id == id || facility._id == id);
    }
    getUSACounties() {
        return counties;
    }
    setCurrentFacilityRecipients(facilityRecipients) {
        this.currentFacilityRecipients = facilityRecipients;
    }
    getCurrentFacilityRecipients() {
        return this.currentFacilityRecipients;
    }
    set currentFacilitySubject$(facility: any) {
        this.currentFacility$.next(facility);
    }

    get currentFacilitySubject$() {
        return this.currentFacility$;
    }
    set _Facilities(facilities: any) {
        this.allFacilities = facilities; //As allFacilities is private and now facilities set from organization
        this.facilities$.next(facilities);
    }

    get _Facilities() {
        return this.facilities$;
    }
    set _currentFacility(facility) {
        // if facility is empty like in case of logout
        if (!facility) {
            this.currentFacility = facility;
            this.currentFacilitySubject$ = facility;
            return;
        }
        facility = JSON.parse(JSON.stringify(facility)); // remove reference;
        const id = facility.id || facility._id;
        facility.id = facility._id = id;
        localStorage.setItem('current-facility', JSON.stringify(facility));
        this.currentFacility = facility;
        this.currentFacilitySubject$ = facility;
    }
    get _currentFacility() {
        const storedFacility = localStorage.getItem('current-facility');
        try {
            return this.currentFacility || (storedFacility ? JSON.parse(storedFacility) : null);
        } catch (error) {
            return null;
        }
    }
    removeCurrentFacility() {
        localStorage.removeItem('current-facility');
    }
    updateFacilities(facilities: any) {
        this._Facilities = facilities;
    }
    //-------------------API-----------//

    // ! not use anywhere
    // getFacilitiesList(forNewUser = false) {
    // we are using '_Facilities' then I think there is no need to return subscribe this function everywhere.
    /*  this.http.get<any>(`${baseUrl}/facility`, { withCredentials: true }).subscribe((facilities) => {
            this.allFacilities = facilities.data;
            if (!forNewUser) {
                const currentFacility = this._currentFacility
                    ? this.allFacilities.find((facility) => facility.id == this._currentFacility.id)
                    : this.allFacilities[0];
                this._currentFacility = currentFacility ? currentFacility : this.allFacilities[0];
            } else {
                this._currentFacility = this.allFacilities[0];
            }
            this._Facilities = this.allFacilities;
        });*/
    // }

    getOrganizationsList() {
        return this.http.get<any>(`${baseUrl}/organization`, { withCredentials: true }); //.pipe(take(1), shareReplay())
    }
    saveFacilityOnBoarding(data) {
        return this.http.post(`${baseUrl}/facility`, data, {
            withCredentials: true,
        });
        /*  .pipe(
                tap((data: any) => {
                    const tempF = {
                        facilityName: data.data.facilityName,
                        facilityNumber: data.data.facilityNumber,
                        facilityType: data.data.facilityType,
                        id: data.data.id,
                        mailingAddress: data.data.mailingAddress,
                        organization: data.data.organization,
                        status: data.data.status,
                    };
                      this._currentFacility = data.data;
                    this.allFacilities.push(tempF);
                    this._Facilities = this.allFacilities;
                })
            );*/
    }
    getFacilityById(id: string) {
        return this.http.get<any>(`${baseUrl}/facility/${id}`, { withCredentials: true });
    }
    getRecipientByFacilityId(facilityId: string) {
        return this.http.get<any>(`${baseUrl}/facility/getRecipientByFacility/${facilityId}`, {
            withCredentials: true,
        });
    }
    removeRecipientAssociationWithFacility(facilityId: string, recipient: any) {
        return this.http.put<any>(
            `${baseUrl}/facility/remove-recipient-association/${facilityId}`,
            { recipient },
            {
                withCredentials: true,
            }
        );
    }

    markDefaultFacility(obj) {
        return this.http.post<any>(`${baseUrl}/user/set-default-facility`, obj, { withCredentials: true });
    }

    toggleFacilityGoLive(id: string, enableGoLive: boolean): Observable<any> {
        const goLiveFacility = { id, enableGoLive };
        return this.http.patch(`${baseUrl}/facility/go-live`, goLiveFacility, { withCredentials: true });
    }
    toggleFacilityCoSign(id: string, enableCoSign: boolean): Observable<any> {
        const coSignFacility = { id, enableCoSign };
        return this.http.patch(`${baseUrl}/facility/co-sign`, coSignFacility, { withCredentials: true });
    }
    mapFacilitiesIntoDropdown() {
        if (this.allFacilities && this.allFacilities.length > 0) {
            const mapFacilities = this.allFacilities.map((item) => {
                return {
                    id: item.id ? item.id : item._id,
                    name: item.facilityName,
                    type: item.facilityType,
                    recipient: item.recipients,
                };
            });
            return mapFacilities;
        }
    }
    updateFacilityOnBoarding(id, data) {
        return this.http
            .post(`${baseUrl}/facility/${id}`, data, {
                withCredentials: true,
            })
            .pipe(
                tap((data: any) => {
                    const index = this.allFacilities.findIndex((item) => item.id == data.data.id);
                    const updatedData = {
                        facilityName: data.data.facilityName,
                        facilityNumber: data.data.facilityNumber,
                        facilityType: data.data.facilityType,
                        id: data.data.id,
                        mailingAddress: data.data.mailingAddress,
                        organization: data.data.organization,
                        status: data.data.status,
                        goLiveEnabled: data.data.goLiveEnabled,
                        facilityOID: data.data.facilityOID,
                    };
                    if (index != -1) {
                        this.allFacilities[index] = updatedData;
                    }

                    if (this._currentFacility.id == data.data.id) this._currentFacility = data.data;
                    this._Facilities = this.allFacilities;
                })
            );
    }
    getMac(data) {
        return this.http.post(`${baseUrl}/mac-provider/find`, data, {
            withCredentials: true,
        });
    }
    getRecentExportDate() {
        const id = this._currentFacility.id;
        return this.http.get<any>(`${baseUrl}/facility/recent-export-date/${id}`);
    }
    updateBillingExportLog() {
        const id = this._currentFacility.id;
        return this.http.put<any>(`${baseUrl}/facility/update-billing-export-log/${id}`, {});
    }

    updateFacilityNPC(payload) {
        return this.http.post(`${baseUrl}/facility/updateNPC`, payload, { withCredentials: true });
    }

    updateMultipleFacilityNPC(payload) {
        return this.http.post(`${baseUrl}/facility/updateMultipleFacilitiesNPC`, payload, { withCredentials: true });
    }
    getFacilitiesByOrganizationIdAndRoles(organizationId: string): any {
        return this.http.get<any>(`${baseUrl}/facility/organization/${organizationId}`, { withCredentials: true });
    }
    getOrganizationFacilitiesById(id: string) {
        return this.http.get<any>(`${baseUrl}/facility/organization/${id}`, { withCredentials: true });
    }
    getFacilitiesByIds(ids: string[], propsWhitelist: string, macAddressRequired = false) {
        return this.http.post<any>(`${baseUrl}/facility/byIds`, {
            facilityIds: ids,
            propsWhitelist,
            macAddressRequired
        }, { withCredentials: true })
    }
}
