//import { setHours } from '@app/helpers/constants';
import { TabItem } from '@app/interfaces';

export const AVAILABILITY_OBJ = {
    duration: {
        //effectiveFrom: setHours(new Date().toDateString()),
        effectiveFrom: '',
        effectiveThru: '',
    },
    days: {
        sun: {
            available: false,
            time: null,
        },
        mon: {
            available: false,
            time: null,
        },
        tue: {
            available: false,
            time: null,
        },
        wed: {
            available: false,
            time: null,
        },
        thu: {
            available: false,
            time: null,
        },
        fri: {
            available: false,
            time: null,
        },
        sat: {
            available: false,
            time: null,
        },
    },
    note: '',
    facility: '',
};

export enum TAB_ACTIONS {
    PERSONAL_INFO = 'PERSONAL_INFO',
    FACILITY_ROLES = 'FACILITY_ROLES',
    ADDITIONAL_DETAILS = 'ADDITIONAL_DETAILS',
    SUBMIT = 'SUBMIT',
}

export const ADD_USER_TABS: TabItem[] = [
    {
        url: TAB_ACTIONS.PERSONAL_INFO,
        title: 'Personal Info',
        highlight: false,
        underline: true,
        isDone: false,
    },
    {
        url: TAB_ACTIONS.FACILITY_ROLES,
        title: 'Facilities & Roles',
        highlight: false,
        underline: false,
        isDone: true,
        unauthorized: true,
    },
    {
        url: TAB_ACTIONS.ADDITIONAL_DETAILS,
        title: 'Additional Details',
        highlight: false,
        underline: false,
        isDone: true,
        unauthorized: true,
    },
];

export enum BASE_ROLES {
    ADMIN = 'SystemAdmin',
    THERAPIST = 'Therapist',
    PHYSICIAN = 'Physician',
    MANAGER = 'Manager',
    STAFF = 'Staff',
    READ_ONLY = 'Read Only',
    SUPER_ADMIN = 'Super Admin',
}

export enum USER_TABS_INDEX {
    PERSONAL_INFO = 0,
    FACILITY_ROLES = 1,
    ADDITIONAL_DETAILS = 2,
}

export const PHYSICIAN_CREDENTIALS = ['M.D.', 'D.O.', 'PA', 'NP', 'DNP'];

// eslint-disable-next-line spellcheck/spell-checker
export const STATES = [
    'Alabama',
    'Alaska',
    'American Samoa',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Federated States of Micronesia',
    'Florida',
    'Georgia',
    'Guam',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Marshall Islands',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Northern Mariana Islands',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Palau',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virgin Island',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
];
