import { Component, Output, Input, EventEmitter, OnInit } from '@angular/core';
import { ROUTER_UTILS, Role } from '@app/helpers';
import { EOMRoutes } from '../../../../src/app/modules/billing/eom-billing/eom-routes';
import { InvoicingProfileRoutes } from '../../../../src/app/modules/billing/invoicing/invoicing-routes';
import { Account } from '@app/models';
import { AuthService, StoreService } from '@app/services';
import { Layout } from '@app/interfaces/layout';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { PatientState } from '@app/store/store.interface';
import { take } from 'rxjs';
import { AuthPolicies } from '@app/helpers/utils';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-left-nav-bar',
    templateUrl: './left-nav-bar.component.html',
    styleUrls: ['./left-nav-bar.component.scss'],
})
export class LeftNavBarComponent implements OnInit {
    @Input() layout: Layout;
    @Output() layoutEvent: EventEmitter<Layout> = new EventEmitter();

    dashboardUrl: string = ROUTER_UTILS.config.base.root;
    dorDashboardUrl: string = ROUTER_UTILS.config.base.dorDashboard;
    therapistDashboardUrl: string = ROUTER_UTILS.config.base.therapistDashboard;
    patientUrl: string = ROUTER_UTILS.config.patients.root;
    pdpmUrl: string = ROUTER_UTILS.config.patients.pdpm.root;
    physicianUrl: string = ROUTER_UTILS.config.physicianAccountsAndDocument.root; // colworx-ak add physicianUrl
    patientListUrl: string = this.patientUrl + '/' + ROUTER_UTILS.config.patients.patientList;
    patientBoardUrl: string = this.patientUrl + '/' + ROUTER_UTILS.config.patients.patientBoard;
    pdpmBoardUrl: string = this.patientUrl + '/' + this.pdpmUrl + '/' + ROUTER_UTILS.config.patients.pdpm.board;
    adminUrl: string = '/' + ROUTER_UTILS.config.admin.root;
    payorUrl: string = this.adminUrl + '/' + ROUTER_UTILS.config.admin.payors.root;
    usersUrl: string = this.adminUrl + '/' + ROUTER_UTILS.config.admin.accounts.root;
    recipientUrl: string = this.adminUrl + '/' + ROUTER_UTILS.config.admin.recipients.home;
    rightsUrl: string = this.adminUrl + '/' + ROUTER_UTILS.config.admin.rights;
    resourcesUrl: string = this.adminUrl + '/' + ROUTER_UTILS.config.admin.resources;
    rolesUrl: string = this.adminUrl + '/' + ROUTER_UTILS.config.admin.roles;
    facilityUrl: string = this.adminUrl + '/' + ROUTER_UTILS.config.admin.facility.root;
    organizationUrl: string = this.adminUrl + '/' + ROUTER_UTILS.config.admin.organization.root;
    integrationUrl: string = this.adminUrl + '/' + ROUTER_UTILS.config.admin.integration.root;

    schedulingUrl: string = ROUTER_UTILS.config.scheduling.root;
    laborHistoryUrl: string = ROUTER_UTILS.config.timeTracking.root + '/' + ROUTER_UTILS.config.timeTracking.dorView;
    laborManagementUrl: string = ROUTER_UTILS.config.labor.root + '/' + ROUTER_UTILS.config.labor.laborManagementLayout;
    reportsUrl: string = ROUTER_UTILS.config.reports.root;
    serviceLogUrl: string = this.reportsUrl + '/' + ROUTER_UTILS.config.reports.serviceLog;
    billingExportUrl: string = this.reportsUrl + '/' + ROUTER_UTILS.config.reports.billingExport;
    standardBillingExportUrl: string = this.reportsUrl + '/' + ROUTER_UTILS.config.reports.standardBillingExport;
    pocDocUrl: string = ROUTER_UTILS.config.physicianAccountsAndDocument.pocDoc; // colworx-ak add pocDocUrl
    userMgUrl: string = this.physicianUrl + '/' + ROUTER_UTILS.config.physicianAccountsAndDocument.account; // colworx-ak add userMgUrl
    physicianPocDocUrl: string = this.physicianUrl + '/' + this.pocDocUrl; // colworx-ak add physican pocDocUrl
    physicianStaffPocDocUrl: string =
        this.physicianUrl + '/' + ROUTER_UTILS.config.physicianAccountsAndDocument.staff + '/' + this.pocDocUrl; // colworx-ak add staff pocDocUrl
    learningUrl: string = ROUTER_UTILS.config.learning.root;
    settingsUrl: string = ROUTER_UTILS.config.settings.root;
    helpUrl: string = ROUTER_UTILS.config.help.root;
    addPatientUrl: string = ROUTER_UTILS.config.addPatients.root;
    adminURL: string = ROUTER_UTILS.config.admin.root;
    schedulerUrl: string = '/' + ROUTER_UTILS.config.scheduling.root + '/' + ROUTER_UTILS.config.scheduling.scheduler;
    schedulerV2Url: string =
        '/' + ROUTER_UTILS.config.scheduling.root + '/' + ROUTER_UTILS.config.scheduling.schedulerV2;
    plannerUrl: string = '/' + ROUTER_UTILS.config.scheduling.root + '/' + ROUTER_UTILS.config.scheduling.planner;
    whiteBoardUrl: string = '/' + ROUTER_UTILS.config.scheduling.root + '/' + ROUTER_UTILS.config.scheduling.whiteBoard;
    tripleCheckReportsUrl: string = this.reportsUrl + '/' + ROUTER_UTILS.config.reports.tripleCheck;
    endOfMonthReportUrl: string = this.reportsUrl + '/' + ROUTER_UTILS.config.reports.endOfMonthReport;
    billingUrl: string = ROUTER_UTILS.config.billing.root;
    eomBillingUrl: string = this.billingUrl + '/' + EOMRoutes.endOfMonthBilling;
    invoicingUrl: string = this.billingUrl + '/' + InvoicingProfileRoutes.invoice;

    mopReport: string = this.reportsUrl + '/' + ROUTER_UTILS.config.reports.mopReport;
    Role = Role;
    account: Account;
    hidden = false;
    tab$: any;
    localTab: any;
    showFacility: boolean;
    policies = AuthPolicies;
    isSuperAdminOnly = false;
    isTherapist = false;
    isManager = false;
    environmentName: string;
    hideNavbarForMobSize = false;
    navigationPermission: {
        dashboard: {
            allowed: boolean;
            operationalDashboard: { allowed: boolean };
            therapistDashboard: { allowed: boolean };
        };
        admissions: { allowed: boolean };
        patients: { allowed: boolean; patientBoard: { allowed: boolean }; pdpmBoard: { allowed: boolean } };
        planPatient: {
            allowed: boolean;
            planner: { allowed: boolean };
            scheduler: { allowed: boolean };
            whiteBoard: { allowed: boolean };
        };
        labor: { allowed: boolean; timeCards: { allowed: boolean }; therapistAvailability: { allowed: boolean } };
        admin: {
            allowed: boolean;
            organization: { allowed: boolean };
            facilities: { allowed: boolean };
            payors: { allowed: boolean };
            users: { allowed: boolean };
            roles: { allowed: boolean };
        };
        systemIntegration: { allowed: boolean; integration: { allowed: boolean } };
        reports: {
            allowed: boolean;
            serviceLog: { allowed: boolean };
            billingReport: { allowed: boolean };
            tripleReport: { allowed: boolean };
            eomReport: { allowed: boolean };
            mbpReport: { allowed: boolean };
        };
        billing: {
            allowed: boolean;
            eom: { allowed: boolean };
            invoicing: { allowed: boolean };
        };
        PocDocument: { allowed: boolean; documents: { allowed: boolean } }; // coloworx-ak: add PocDocument
        UserManagement: { allowed: boolean; user: { allowed: boolean } }; // coloworx-ak: add UserManagement
    };

    constructor(
        public AuthService: AuthService,
        private router: Router,
        private store: Store<{ schedule: any; patient: PatientState }>,
        private storeService: StoreService
    ) {
        // colworx-sk
        const screenWidth = window.innerWidth;

        if (screenWidth >= 350 && screenWidth <= 768) {
            this.hideNavbarForMobSize = true;
        }
        // colworx-sk  
        this.AuthService.account.subscribe((x) => {
            this.account = x;
        });
        this.AuthService.roleSubject.subscribe(() => {
            this.isManager = this.AuthService.isManager;
            this.isSuperAdminOnly = this.AuthService.isSuperAdminOnly;
            this.isTherapist = this.AuthService.isTherapist;
            this.initializeResourcesPermission();
        });
        this.tab$ = store.pipe(select('schedule'));
        this.store.pipe(select('schedule')).forEach((item) => (this.localTab = item.selectedTab));
        // to show hide facility
        if (this.AuthService.isSystemAdmin || this.AuthService.isManager) {
            this.showFacility = true;
        }

        this.isManager = this.AuthService.isManager;
        this.isSuperAdminOnly = this.AuthService.isSuperAdminOnly;
        this.isTherapist = this.AuthService.isTherapist;
        this.environmentName = environment.environmentName;
    }

    ngOnInit(): void {
        this.initializeResourcesPermission();
    }

    toggleHidden() {
        this.hidden = !this.hidden;
        // this.layoutEvent.emit(this.hidden === true ? 'hidden' : 'expanded');
    }

    isActive(urls: string[]): boolean {
        let result = false;
        const URL = this.router.url;
        for (let index = 0; index < urls.length; index++) {
            result = URL.includes(urls[index]);
            if (result == true) break;
        }
        return result;
    }

    showMenu(policy: string) {
        return this.AuthService.applyPolicy(policy);
    }

    destroyCurrentPatientState() {
        this.store
            .select('patient')
            .pipe(take(1))
            .subscribe((data) => {
                if (data.id) {
                    this.storeService.resetAllPatientData();
                }
            });
        this.storeService.admissionStepper = ROUTER_UTILS.config.addPatients.personalInformation;
    }

    addDashboardLink() {
        if (this.AuthService.isManager && this.AuthService.isTherapist) {
            return;
        } else {
            return this.dashboardUrl;
        }
    }

    // ====================
    // Private Methods
    // ====================
    private initializeResourcesPermission(): void {
        this.navigationPermission = {
            dashboard: {
                allowed:
                    this.showMenu(this.policies.OperationsDashboard.Access) ||
                    this.showMenu(this.policies.TherapistDashboard.Access) ||
                    this.isSuperAdminOnly,
                operationalDashboard: {
                    allowed: this.showMenu(this.policies.OperationsDashboard.Access),
                },
                therapistDashboard: {
                    allowed: this.showMenu(this.policies.TherapistDashboard.Access) && this.isTherapist,
                },
            },
            admissions: {
                allowed: this.showMenu(this.policies.Patient.Add),
            },
            patients: {
                allowed: this.showMenu(this.policies.Patient.View),
                patientBoard: { allowed: this.showMenu(this.policies.PatientBoard.View) },
                pdpmBoard: { allowed: this.showMenu(this.policies.PDPMBoard.View) },
            },
            planPatient: {
                allowed: this.showMenu(this.policies.Planner.View) || this.showMenu(this.policies.Scheduler.View),
                planner: { allowed: this.showMenu(this.policies.Planner.View) },
                scheduler: { allowed: this.showMenu(this.policies.Scheduler.View) },
                whiteBoard: { allowed: this.showMenu(this.policies.Whiteboard.View) },
            },
            labor: {
                allowed:
                    this.showMenu(this.policies.Timecards.View) ||
                    this.showMenu(this.policies.TherapistAvailability.View),
                timeCards: { allowed: this.showMenu(this.policies.Timecards.View) },
                therapistAvailability: { allowed: this.showMenu(this.policies.TherapistAvailability.View) },
            },
            admin: {
                allowed:
                    this.showMenu(this.policies.Facilities.View) ||
                    this.showMenu(this.policies.Payors.View) ||
                    this.showMenu(this.policies.Roles.View) ||
                    this.showMenu(this.policies.Users.View),
                organization: { allowed: this.AuthService.isSuperAdmin },
                facilities: { allowed: this.showMenu(this.policies.Facilities.View) },
                payors: { allowed: this.showMenu(this.policies.Payors.View) },
                users: { allowed: this.showMenu(this.policies.Users.View) },
                roles: { allowed: this.showMenu(this.policies.Roles.View) },
            },
            systemIntegration: {
                allowed: this.showMenu(this.policies.MatrixCare.Access),
                integration: { allowed: this.showMenu(this.policies.MatrixCare.Access) },
            },
            reports: {
                allowed:
                    this.showMenu(this.policies.ServiceLog.View) ||
                    this.showMenu(this.policies.BillingExport.View) ||
                    this.showMenu(this.policies.TripleCheckReport.View) ||
                    this.showMenu(this.policies.EOMReport.View) ||
                    this.showMenu(this.policies.MinutesByPayor.View),
                serviceLog: { allowed: this.showMenu(this.policies.ServiceLog.View) },
                billingReport: { allowed: this.showMenu(this.policies.BillingExport.View) },
                tripleReport: { allowed: this.showMenu(this.policies.TripleCheckReport.View) },
                eomReport: { allowed: this.showMenu(this.policies.EOMReport.View) },
                mbpReport: { allowed: this.showMenu(this.policies.MinutesByPayor.View) },
            },
            billing: {
                allowed: this.showMenu(this.policies.EOM.View) || this.showMenu(this.policies.Invoicing.View),
                eom: { allowed: this.showMenu(this.policies.EOM.View) },
                invoicing: { allowed: this.showMenu(this.policies.Invoicing.View) },
            },
            PocDocument: {
                // coloworx-ak: add PocDocument
                allowed: this.showMenu(this.policies.PocDocuments.View),
                documents: { allowed: this.showMenu(this.policies.PocDocuments.View) },
            },
            UserManagement: {
                // coloworx-ak: add UserManagement
                allowed: this.showMenu(this.policies.UserManagement.Add),
                user: { allowed: this.showMenu(this.policies.UserManagement.Add) },
            },
        };
    }
}
