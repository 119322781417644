import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// colworx-ak: A Modal component for physician or staff can add comments
@Component({
    selector: 'app-documents-comments-modal',
    templateUrl: './documents-comments-modal.component.html',
    styleUrls: ['./documents-comments-modal.component.scss'],
})
export class DocumentCommentsModalComponent {
    content: any;
    commentForm = new FormGroup({
        comment: new FormControl('', Validators.required),
    });

    constructor(@Inject(DIALOG_DATA) private data, private dialogRef: DialogRef) {
        this.content = data;
    }

    onClose(data?: any) {
        this.dialogRef.close(data);
    }
    addComment() {
        this.onClose(this.commentForm.value);
    }
}
