import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first, Observable, Subject, takeUntil } from 'rxjs';
import { environment } from '@environments/environment';
import { FacilityAdmission, Patient, TherapyAdmission, TherapyDiscipline } from '@app/interfaces';
import { Store } from '@ngrx/store';
import { PatientState } from '@app/store/store.interface';
import { spinnerDocumentStateChangeAction, therapyadmissionChangedAction } from '@app/store/actions/index';
import { Doc_Notes, DOCUMENTATION_CONSTANTS } from '@app/interfaces/documentation';
import { cloneDeep } from 'lodash';
import { EvaluationQueryParams } from '@app/interfaces/internal-feed.interface';
import { RequestAbortController } from '@app/helpers/lib';
import { StoreService } from './store.service';
import { getEndOfDay } from '@app/helpers/utils';

const baseUrl = `${environment.apiUrl}`;

@Injectable({ providedIn: 'root' })
export class DocumentationService {
    requestAbortController: RequestAbortController;
    integrationEntity: any;
    facilityId: string;
    integratedOrganizationId: string;
    tab$: Observable<any>;
    patient: PatientState;
    docStatusUpdate: Subject<boolean> = new Subject<boolean>();
    docPrintLayoutStatus: Subject<boolean> = new Subject<boolean>();
    signatureProcessStatus = false;
    facilityAdmission: FacilityAdmission;
    currentTherapyAdmission: any;
    // allTherapyAdmissions: TherapyAdmission[];
    selectedDiscipline: string;
    allTherapyAdmissions: TherapyAdmission[];
    documentNoteIndex: number;
    therapistId = '';
    sortStatus = {};
    goalTrends = null;

    constructor(
        private http: HttpClient,
        private store: Store<{
            therapyDiscipline: TherapyDiscipline;
            therapyAdmission: TherapyAdmission[];
            patient: Patient;
            facilityAdmission: FacilityAdmission;
        }>,
        private storeService: StoreService
    ) {
        this.requestAbortController = new RequestAbortController();
        // this.getCurrentTherapyAdmission();
    }
    private readonly onDestroy = new Subject<void>();
    // getCurrentTherapyAdmission() {
    //   this.store.select('therapyAdmission').subscribe((therapyAdmissions) => {
    //     this.allTherapyAdmissions = therapyAdmissions;
    //     this.store.select('therapyDiscipline').subscribe((discipline) => {
    //       if (!discipline) discipline = 'PT';
    //       this.selectedDiscipline = 'PT';
    //       this.currentTherapyAdmission = cloneDeep(
    //         therapyAdmissions.filter((admission) => admission.therapyDiscipline == this.selectedDiscipline)[0]
    //       );
    //       if (this.currentTherapyAdmission) this.initializeDocumentation();
    //     });
    //   });
    // }

    setCurrentTherapyAdmission(allAdmissions: TherapyAdmission[], admission: any, selectedDiscipline: string) {
        this.allTherapyAdmissions = allAdmissions;
        this.currentTherapyAdmission = admission;
        this.selectedDiscipline = selectedDiscipline;
    }

    setTherapistId(id: string) {
        this.therapistId = id;
    }
    getTherapistId(): string {
        return this.therapistId;
    }
    coSignDocument(documentType: string, documentId: string, coSigned: any, exportData) {
        return this.http.post(`${baseUrl}/documentation/co-sign-doc`, {
            documentType,
            documentId,
            coSigned,
            exportData,
        });
    }
    // To update documentation
    updateDocumentation(payload, docName = 'evaluation', queryParams?: EvaluationQueryParams) {
        let updatedTherapyAdmission = cloneDeep(payload);
        return new Promise((resolve) => {
            this.store.dispatch(
                spinnerDocumentStateChangeAction({
                    selectedLoadingSpinnerState: { isLoading: true },
                })
            );
            this.storeService.setTherapyAdmissionState(true);
            const therapyAdmissions = cloneDeep(
                this.allTherapyAdmissions.filter((admission) => admission._id !== updatedTherapyAdmission._id)
            );
            this.changeIdToUnderScoreId(updatedTherapyAdmission);
            let mapFunction = '';
            if (docName === 'recertificationNote') {
                mapFunction = 'map' + 'recertification'.charAt(0).toUpperCase() + 'recertification'.slice(1);
            } else mapFunction = 'map' + docName.charAt(0).toUpperCase() + docName.slice(1);
            const documentation_id = updatedTherapyAdmission.documentation.id;
            const document = updatedTherapyAdmission.documentation[docName];

            const isArray = Array.isArray(document);
            const mappedDocument = isArray
                ? this[mapFunction](document[this.documentNoteIndex], documentation_id)
                : this[mapFunction](document, documentation_id);

            if (isArray ? document[this.documentNoteIndex].id : document.id) {
                // remove ternary operator once goals is connected to all documents
                const previousGoals =
                    docName === 'evaluation'
                        ? updatedTherapyAdmission.documentation[docName].planOfCare.goals.filter((x) => !x._id)
                        : '';
                this.signDocument(docName, mappedDocument, queryParams)
                    .pipe(first())
                    .subscribe((result: any) => {
                        if (result.success) {
                            result.data.assignedTherapists = mappedDocument.assignedTherapists;
                            if (result.data.note) {
                                result.data.note.assignedTherapists = mappedDocument.assignedTherapists;
                            }
                            // if result.meta has dailyNoteId then delete that note from Current Therapy Admission
                            const dailyNoteId = result.meta;
                            this.store.dispatch(
                                spinnerDocumentStateChangeAction({
                                    selectedLoadingSpinnerState: { isLoading: false },
                                })
                            );
                            this.storeService.setTherapyAdmissionState(false);
                            if (isArray) {
                                if (docName == 'progressNote') {
                                    updatedTherapyAdmission.documentation[docName][this.documentNoteIndex] =
                                        result.data.note;
                                    if (result.data.note.status == DOCUMENTATION_CONSTANTS.COMPLETED) {
                                        updatedTherapyAdmission = result.data.therapyAdmission;
                                    }
                                } else if (docName == 'dailyNote') {
                                    result.data.note.assignedTherapists = mappedDocument.assignedTherapists;
                                    updatedTherapyAdmission.documentation[docName][this.documentNoteIndex] =
                                        result.data.note;
                                    if (result.data.note.status == DOCUMENTATION_CONSTANTS.COMPLETED) {
                                        updatedTherapyAdmission = result.data.therapyAdmission;
                                    }
                                } else if (docName == 'recertificationNote') {
                                    result.data.note.assignedTherapists = mappedDocument.assignedTherapists;
                                    result.data?.note?.plan?.goals?.forEach((goal) => {
                                        goal['maxTargetDate'] = result.data.note.plan.certification.throughDate;
                                        goal['minTargetDate'] = result.data.note.plan.certification.fromDate;
                                    });
                                    updatedTherapyAdmission.documentation[docName][this.documentNoteIndex] =
                                        result.data.note;
                                    if (result.data.note.status == DOCUMENTATION_CONSTANTS.COMPLETED) {
                                        updatedTherapyAdmission = result.data.therapyAdmission;
                                    }
                                } else if (docName == 'updatedPlanOfCareNote') {
                                    result.data?.note?.plan?.goals?.forEach((goal) => {
                                        goal['maxTargetDate'] = result.data.note.plan.certification.throughDate;
                                        goal['minTargetDate'] = result.data.note.plan.certification.fromDate;
                                    });
                                    updatedTherapyAdmission.documentation[docName][this.documentNoteIndex] =
                                        result.data.note;
                                    if (result.data.note.status == DOCUMENTATION_CONSTANTS.COMPLETED) {
                                        updatedTherapyAdmission = result.data.therapyAdmission;
                                    }
                                } else
                                    updatedTherapyAdmission.documentation[docName][this.documentNoteIndex] =
                                        result.data;
                            } else {
                                result.data?.note?.planOfCare?.goals.forEach((goal) => {
                                    goal['maxTargetDate'] = result.data.note.planOfCare.certification.throughDate;
                                    goal['minTargetDate'] = result.data.note.planOfCare.certification.fromDate;
                                });
                                if (docName === 'evaluation') {
                                    updatedTherapyAdmission.documentation[docName] = result.data.note;
                                    if (result.data.note?.status === DOCUMENTATION_CONSTANTS.COMPLETED) {
                                        updatedTherapyAdmission = result.data.therapyAdmission;
                                    }
                                } else {
                                    updatedTherapyAdmission.documentation[docName] = result.data;
                                }
                            }
                            if (dailyNoteId && dailyNoteId.length) {
                                updatedTherapyAdmission.documentation.dailyNote = [];
                            }
                            // If discharge note is completed, don't show upcoming notes
                            if (
                                docName === 'dischargeNote' &&
                                result.data.status === DOCUMENTATION_CONSTANTS.COMPLETED
                            ) {
                                const endOfCareDate = getEndOfDay(new Date(result.data.assessment.endOfCareDate));
                                updatedTherapyAdmission.documentation.dailyNote =
                                    updatedTherapyAdmission.documentation.dailyNote.filter(
                                        (note) => new Date(note.day) < endOfCareDate
                                    );
                                updatedTherapyAdmission.documentation.progressNote =
                                    updatedTherapyAdmission.documentation.progressNote.filter(
                                        (note) => new Date(note.day) < endOfCareDate
                                    );
                            }
                            therapyAdmissions.push(updatedTherapyAdmission);
                            this.store.dispatch(
                                therapyadmissionChangedAction({
                                    therapyadmission: therapyAdmissions,
                                })
                            );
                            //this.documentNoteIndex = undefined;
                            // Remove if condition once goals is connected to all documents
                            if (docName === 'evaluation') {
                                const goal = this.addGoalIdToAssessmentItem(
                                    previousGoals,
                                    result.data.note.planOfCare.goals
                                );
                                //Resolves goal so that it can be binded with patient assessment
                                if (goal) resolve(goal);
                                else {
                                    resolve(result);
                                }
                            } else {
                                resolve(result);
                            }
                        }
                    });
            }
            /*else {
        this.documentCreate(docName, mappedDocument)
          .pipe(first())
          .subscribe((result: any) => {
            if (result.success) {
              this.store.dispatch(
                spinnerDocumentStateChangeAction({
                  selectedLoadingSpinnerState: { isLoading: false },
                })
              );
              if (isArray) {
                updatedTherapyAdmission.documentation[docName][this.documentNoteIndex] = result.data;
              } else updatedTherapyAdmission.documentation[docName] = result.data;
              therapyAdmissions.push(updatedTherapyAdmission);
              this.store.dispatch(
                therapyadmissionChangedAction({
                  therapyadmission: therapyAdmissions,
                })
              );
              this.documentNoteIndex = undefined;
            }
          });
      }*/
        });
    }
    updateDocumentationV2(payload, docName = 'evaluation', queryParams?: EvaluationQueryParams) {
        let updatedTherapyAdmission = cloneDeep(payload);
        this.requestAbortController.cancelPreviousRequest();
        return new Promise((resolve) => {
            this.store.dispatch(
                spinnerDocumentStateChangeAction({
                    selectedLoadingSpinnerState: { isLoading: true },
                })
            );
            const therapyAdmissions = cloneDeep(
                this.allTherapyAdmissions.filter((admission) => admission._id != updatedTherapyAdmission._id)
            );
            const admissions = cloneDeep(this.allTherapyAdmissions);
            admissions.forEach((admission: TherapyAdmission) => {
                if (admission?.documentation?.dailyNote?.length) {
                    const docIndex = admission.documentation.dailyNote.findIndex(
                        (note) => note.id === updatedTherapyAdmission.id
                    );
                    if (docIndex !== -1) {
                        admission.documentation.dailyNote[docIndex] = updatedTherapyAdmission;
                    }
                }
            });
            this.storeService.setTherapyAdmission(this.allTherapyAdmissions);
            this.changeIdToUnderScoreId(updatedTherapyAdmission);
            let mapFunction = '';
            if (docName === 'recertificationNote') {
                mapFunction = 'map' + 'recertification'.charAt(0).toUpperCase() + 'recertification'.slice(1);
            } else mapFunction = 'map' + docName.charAt(0).toUpperCase() + docName.slice(1);
            const documentation_id = updatedTherapyAdmission.documentation.id;
            const document = updatedTherapyAdmission.documentation[docName];

            const isArray = Array.isArray(document);
            const mappedDocument = isArray
                ? this[mapFunction](document[this.documentNoteIndex], documentation_id)
                : this[mapFunction](document, documentation_id);

            if (isArray ? document[this.documentNoteIndex].id : document.id) {
                // remove ternary operator once goals is connected to all documents
                const previousGoals =
                    docName === 'evaluation'
                        ? updatedTherapyAdmission.documentation[docName].planOfCare.goals.filter((x) => !x._id)
                        : '';
                this.documentNoteUpdate(docName, mappedDocument, queryParams)
                    .pipe(first(), takeUntil(this.requestAbortController.abortController))
                    .subscribe((result: any) => {
                        if (result.success) {
                            result.data.assignedTherapists = mappedDocument.assignedTherapists;
                            // if result.meta has dailyNoteId then delete that note from Current Therapy Admission
                            const dailyNoteId = result.meta;
                            this.store.dispatch(
                                spinnerDocumentStateChangeAction({
                                    selectedLoadingSpinnerState: { isLoading: false },
                                })
                            );
                            if (isArray) {
                                if (docName == 'progressNote') {
                                    updatedTherapyAdmission.documentation[docName][this.documentNoteIndex] =
                                        result.data;
                                } else if (docName == 'dailyNote') {
                                    result.data.note.assignedTherapists = mappedDocument.assignedTherapists;
                                    updatedTherapyAdmission.documentation[docName][this.documentNoteIndex] =
                                        result.data.note;
                                    if (result.data.note.status == DOCUMENTATION_CONSTANTS.COMPLETED) {
                                        updatedTherapyAdmission = result.data.therapyAdmission;
                                    }
                                } else
                                    updatedTherapyAdmission.documentation[docName][this.documentNoteIndex] =
                                        result.data;
                            } else updatedTherapyAdmission.documentation[docName] = result.data;
                            if (dailyNoteId && dailyNoteId.length) {
                                updatedTherapyAdmission.documentation.dailyNote = [];
                            }
                            // If discharge note is completed, don't show upcoming notes
                            if (
                                docName === 'dischargeNote' &&
                                result.data.status === DOCUMENTATION_CONSTANTS.COMPLETED
                            ) {
                                updatedTherapyAdmission.documentation.dailyNote =
                                    updatedTherapyAdmission.documentation.dailyNote.filter(
                                        (note) => note.status !== DOCUMENTATION_CONSTANTS.UPCOMING
                                    );
                            }
                            therapyAdmissions.push(updatedTherapyAdmission);
                            this.store.dispatch(
                                therapyadmissionChangedAction({
                                    therapyadmission: therapyAdmissions,
                                })
                            );
                            //this.documentNoteIndex = undefined;
                            // Remove if condition once goals is connected to all documents
                            if (docName === 'evaluation') {
                                const goal = this.addGoalIdToAssessmentItem(
                                    previousGoals,
                                    result.data.planOfCare.goals
                                );
                                //Resolves goal so that it can be binded with patient assessment
                                if (goal) resolve(goal);
                                else {
                                    resolve(result);
                                }
                            } else {
                                resolve(result);
                            }
                        }
                    });
            }
        });
    }

    addGoalIdToAssessmentItem(previousGoals, newGoals) {
        return newGoals.find((x) => x.goal === previousGoals[0]?.goal);
    }

    documentCreate(name, data) {
        if (name === 'recertificationNote') name = 'recertification';
        if (name === 'updatedPlanOfCareNote') name = 'upoc';
        return this.http.post(`${baseUrl}/${name}/create`, data, {
            withCredentials: true,
        });
    }
    documentNoteUpdate(
        name,
        data,
        queryParams?: {
            facilityId: string;
            integratedPatientId: string;
            integratedOrganizationName?: string;
            integratedOrganizationId?: string;
            therapyDiscipline?: string;
            patientName?: string;
            documentationId?: string;
            payor?: string;
        }
    ) {
        if (name === 'dailyNote' && (data.status === 'Withheld' || data.status === 'Refused')) name = 'dailyNote/withheld-refused';
        if (name === 'recertificationNote') name = 'recertification';
        if (name === Doc_Notes.UPOC) name = 'upoc';
        if (
            queryParams?.facilityId !== undefined &&
            queryParams?.integratedPatientId !== undefined &&
            queryParams?.integratedOrganizationName !== undefined &&
            queryParams?.integratedOrganizationId !== undefined &&
            queryParams?.therapyDiscipline === undefined &&
            queryParams?.patientName === undefined
        )
            return this.http.put(
                `${baseUrl}/${name}/${data._id}?facilityId=${queryParams.facilityId}&integratedPatientId=${queryParams.integratedPatientId}&integratedOrganizationName=${queryParams.integratedOrganizationName}&integratedOrganizationId=${queryParams.integratedOrganizationId}`,
                data,
                {
                    withCredentials: true,
                }
            );
        else if (queryParams?.therapyDiscipline !== undefined && queryParams?.patientName !== undefined)
            return this.http.put(
                `${baseUrl}/${name}/${data._id}?facilityId=${queryParams.facilityId}&integratedPatientId=${queryParams.integratedPatientId}&therapyDiscipline=${queryParams.therapyDiscipline}&patientName=${queryParams.patientName}&documentationId=${queryParams.documentationId}&payor=${queryParams.payor}`,
                data,
                {
                    withCredentials: true,
                }
            );
        else
            return this.http.put(`${baseUrl}/${name}/${data._id}`, data, {
                withCredentials: true,
            });
    }
    signDocument(
        name,
        data,
        queryParams?: {
            facilityId: string;
            integratedPatientId: string;
            integratedOrganizationName?: string;
            integratedOrganizationId?: string;
            therapyDiscipline?: string;
            patientName?: string;
            documentationId?: string;
            payor?: string;
        }
    ) {
        if (name === 'dailyNote') name = 'dailyNote/sign'
        if (name === 'recertificationNote') name = 'recertification';
        if (name === Doc_Notes.UPOC) name = 'upoc';
        if (
            queryParams?.facilityId !== undefined &&
            queryParams?.integratedPatientId !== undefined &&
            queryParams?.integratedOrganizationName !== undefined &&
            queryParams?.integratedOrganizationId !== undefined &&
            queryParams?.therapyDiscipline === undefined &&
            queryParams?.patientName === undefined
        )
            return this.http.put(
                `${baseUrl}/${name}/${data._id}?facilityId=${queryParams.facilityId}&integratedPatientId=${queryParams.integratedPatientId}&integratedOrganizationName=${queryParams.integratedOrganizationName}&integratedOrganizationId=${queryParams.integratedOrganizationId}`,
                data,
                {
                    withCredentials: true,
                }
            );
        else if (queryParams?.therapyDiscipline !== undefined && queryParams?.patientName !== undefined)
            return this.http.put(
                `${baseUrl}/${name}/${data._id}?facilityId=${queryParams.facilityId}&integratedPatientId=${queryParams.integratedPatientId}&therapyDiscipline=${queryParams.therapyDiscipline}&patientName=${queryParams.patientName}&documentationId=${queryParams.documentationId}&payor=${queryParams.payor}`,
                data,
                {
                    withCredentials: true,
                }
            );
        else
            return this.http.put(`${baseUrl}/${name}/${data._id}`, data, {
                withCredentials: true,
            });
    }
    documentAddendumNote(name, id, data) {
        return this.http.patch(`${baseUrl}/${name}/addAddendumNote/${id}`, data, {
            withCredentials: true,
        });
    }

    /**
     * Changes 'id' to '_id'
     */
    mapSkilledServices(admission) {
        if (admission?.documentation?.evaluation?.planOfCare?.skilledServices?.length) {
            const skilledServices = admission.documentation.evaluation.planOfCare.skilledServices;
            admission.documentation.evaluation.planOfCare.skilledServices = skilledServices.map((cpt) => {
                const formatted = {
                    _id: cpt.id,
                    ...cpt,
                };
                delete formatted.id;
                return formatted;
            });
        }
    }

    /**
     * Perform ${action} at each level of Nested object with Array support
     * ${action} = replace 'id' with '-id'
     * */
    changeIdToUnderScoreId(data: any) {
        if (!data || typeof data === 'string') {
            return;
        }

        if (this.isArray(data)) {
            data.forEach((elem) => {
                this.changeIdToUnderScoreId(elem);

                if (this.isObject(elem)) {
                    this.switchIdsIfExists(elem);
                }
            });
        }

        if (this.isObject(data)) {
            Object.keys(data).forEach((key) => {
                const elem = data[key];
                this.changeIdToUnderScoreId(elem);

                if (this.isObject(elem)) {
                    this.switchIdsIfExists(elem);
                }
            });
        }
    }

    isArray = (data: any): boolean => data && typeof data === 'object' && Array.isArray(data);
    isObject = (data: any): boolean => data && typeof data === 'object' && !Array.isArray(data);

    switchIdsIfExists(elem: any): void {
        if (elem && (elem._id || elem.id)) {
            elem._id = elem.id ? elem.id : elem._id;
            // delete elem['id'];
        }
    }

    mapEvaluation(evaluation) {
        return { _id: evaluation.id, ...evaluation };
        // return {
        //   _id: evaluation.id,
        //   medicalReview: evaluation.medicalReview,
        //   backgroundAssessment: {
        //     patientGoals: evaluation.backgroundAssessment.patientGoals || [],
        //     priorLevelOfFunction: evaluation.backgroundAssessment.priorLevelOfFunction || '',
        //     socialDeterminantsOfHealth: evaluation.backgroundAssessment.socialDeterminantsOfHealth || [],
        //     environmentalFactors: evaluation.backgroundAssessment.environmentalFactors || [],
        //     previousTherapy: evaluation.backgroundAssessment.previousTherapy || '',
        //     careGiverEducation: evaluation.backgroundAssessment.careGiverEducation || '',
        //   },
        //   medicalNecessity: {
        //     therapyNecessity: evaluation.medicalNecessity.therapyNecessity || '',
        //     interventionApproaches: evaluation.medicalNecessity.interventionApproaches || '',
        //   },
        //   patientAssessment: {
        //     functionalDeficits: evaluation.patientAssessment.functionalDeficits || [],
        //     impairments: evaluation.patientAssessment.impairments || [],
        //     standardizedTests: evaluation.patientAssessment.standardizedTests || [],
        //   },
        //   planOfCare: {
        //     // goal: evaluation.planOfCare.goal.description || '',
        //     frequencyAndDuration: evaluation.planOfCare.frequencyAndDuration || [],
        //     skilledServices: evaluation.planOfCare.skilledServices || [],
        //     goalPurpose: evaluation.planOfCare.goalPurpose || '',
        //     anticipatedDischarge: {
        //       dischargePlans: evaluation.planOfCare.anticipatedDischarge.anticipatedDischargePlans || '',
        //       dischargeDestination: evaluation.planOfCare.anticipatedDischarge.anticipatedDischargeDestination || '',
        //     },
        //     temp: evaluation.planOfCare.temp || '',
        //     goals: evaluation.planOfCare.goals || [],
        //     certification: {
        //       fromDate: evaluation.planOfCare.certification.certificationFrom || '',
        //       throughDate: evaluation.planOfCare.certification.certificationThrough || '',
        //     },
        //     // goals: evaluation.planOfCare.goals.description || [],
        //   },
        //   evaluationType: evaluation.evaluationType,
        //   signed: { signDate: evaluation.signed?.signDate, signedBy: evaluation.signed?.signedBy },
        // };
    }

    mapRecertification(reCertNote, documentationId) {
        return {
            documentation_id: documentationId,
            _id: reCertNote?.id,
            ...reCertNote,
        };
        /* if (reCertNote) {
      return {
        documentation_id: documentationId,
        _id: reCertNote.id,
        subjectiveAssessment: {
          patientAssessmentSinceTreatmentStarted: reCertNote.subjectiveAssessment.description,
        },
        objectiveAssessment: {
          vitals: {
            respiratoryRate: reCertNote.objectiveAssessment.description.respiratoryRate,
            temperature: reCertNote.objectiveAssessment.description.temperature,
            pulseRate: reCertNote.objectiveAssessment.description.pulseRate,
            bloodPressure: reCertNote.objectiveAssessment.description.bloodPressure,
            saturation: reCertNote.objectiveAssessment.description.saturation,
          },
        },
        assessment: {
          clinicalAssessment: reCertNote.assessment.description.clinicalImpression,
          therapyNecessity: reCertNote.assessment.description.therapyNecessity,
          prognosisForAchievingRehabGoals: reCertNote.assessment.description.prognosisAchievingRehabGoals,
          reasonForContinuingTreatment: reCertNote.assessment.description.reasonContinuingTreatment,
          updateToTreatment: reCertNote.assessment.description.updatesToTreatment,
          anticipatedDischarge: {
            dischargeDestination: reCertNote.assessment.description.anticipatedDischargeDestination,
            dischargePlan: reCertNote.assessment.description.anticipatedDischargePlans,
          },
        },
      };
    } else return { documentation_id: documentationId };*/
    }

    mapDischargeNote(dischargeNote, documentationId) {
        return {
            documentation_id: documentationId,
            _id: dischargeNote?.id,
            ...dischargeNote,
        };
        /* if (dischargeNote) {
      return {
        documentation_id: documentationId,
        _id: dischargeNote.id,
        subjectiveAssessment: {
          patientAssessmentSinceTreatmentStarted: dischargeNote.subjectiveAssessment.description,
        },
        assessment: {
          clinicalImpression: dischargeNote.assessment.description.clinicalImpression,
          endOfCareReason: dischargeNote.assessment.description.endOfCareReason,
        },
        plan: {
          dischargeInstructions: dischargeNote.assessmentDischarge.description.dischargeInstructions,
          dischargeDestination: dischargeNote.dischargeDestination.description,
          caregiverEducation: dischargeNote.dischargePlans.description,
        },
        signed: { signDate: dischargeNote.signed?.signDate, signedBy: dischargeNote.signed?.signedBy },
      };
    } else return { documentation_id: documentationId };*/
    }

    mapProgressNote(progressNote, documentationId) {
        return {
            documentation_id: documentationId,
            therapistId: this.therapistId,
            _id: progressNote?.id,
            ...progressNote,
        };
        // if (progressNote) {
        //   return {
        //     documentation_id: documentationId,
        //     _id: progressNote.id,
        //     subjectiveAssessment: progressNote.subjectiveAssessment.description,
        //     clinicalImpression: progressNote.objectiveAssessment.description,
        //     plan: progressNote.plan.description,
        //     signed: { signDate: progressNote.signed?.signDate, signedBy: progressNote.signed?.signedBy },
        //   };
        // } else return { documentation_id: documentationId };
    }

    mapDailyNote(dailyNote, documentationId) {
        return {
            documentation_id: documentationId,
            therapistId: this.therapistId,
            _id: dailyNote?.id,
            ...dailyNote,
        };
        /* if (dailyNote) {
      return {
        documentation_id: documentationId,
        _id: dailyNote.id,
        subjectiveAssessment: {
          patientAssessmentSinceLastWeek: dailyNote.subjectiveAssessment.description,
        },
        objectiveAssessment: {
          vitals: {
            respiratoryRate: dailyNote.objectiveAssessment.description.respiratoryRate,
            temperature: dailyNote.objectiveAssessment.description.temperature,
            pulseRate: dailyNote.objectiveAssessment.description.pulseRate,
            bloodPressure: dailyNote.objectiveAssessment.description.bloodPressure,
            o2Saturation: dailyNote.objectiveAssessment.description.saturation,
          },
          careGiverEducation: dailyNote.caregiverEducation.description,
        },
        assessment: {
          treatmentTolerance: dailyNote.assessment.description.treatmentTolerance,
          responseToTreatment: dailyNote.assessment.description.responseTreatment,
        },
        plan: {
          planForNextTreatment: dailyNote.plan.description,
        },
        signed: { signDate: dailyNote.signed?.signDate, signedBy: dailyNote.signed?.signedBy },
      };
    } else return { documentation_id: documentationId };*/
    }
    mapAddendum(addendum, documentationId) {
        if (addendum) {
            return {
                // for time being
                documentation_id: documentationId,
                _id: addendum.id,
            };
        } else return { documentation_id: documentationId };
    }
    mapUpdatedPlanOfCareNote(upoc, documentationId) {
        return { documentation_id: documentationId, _id: upoc?.id, ...upoc };
        // if (upoc) {
        //   return {
        //     // for time being
        //     documentation_id: documentationId,
        //     _id: upoc.id,
        //   };
        // } else return { documentation_id: documentationId };
    }
    // set SelectDocumentNoteIndex(val: number) {
    //   this.documentNoteIndex = val;
    // }

    // get SelectDocumentNoteIndex() {
    //   return this.documentNoteIndex;
    // }

    getSelectedDocumentIndex(doc: string, id: string) {
        //if (doc === 'reCertNote') doc = 'recertification';
        const index = this.currentTherapyAdmission.documentation[doc].findIndex((x: any) => x.id == id);
        this.documentNoteIndex = index;
        return index;
    }

    getClinicalSummary(facilityId: string) {
        return this.http.get<any>(`${baseUrl}/documentation/clinical-summary/${facilityId}`, { withCredentials: true });
    }

    initializeDocumentation() {
        if (this.currentTherapyAdmission) {
            if (!this.currentTherapyAdmission.documentation) {
                this.currentTherapyAdmission.documentation = {
                    evaluation: {
                        medicalReview: {
                            reasonForReferral: {
                                description: '',
                                required: true,
                            },
                            chiefComplaint: {
                                description: '',
                                required: true,
                            },
                            precautions: { description: [], required: true },
                            contraindications: { description: [], required: true },
                            medications: { description: [], required: true },
                            treatmentDiagnosis: { description: [], required: true },
                            medicalDiagnosis: { description: [], required: true },
                        },
                        backgroundAssessment: {
                            patientGoals: { descriptions: [], required: false },
                            priorLevelOfFunction: {
                                description: '',
                                required: true,
                            },
                            socialDeterminantsOfHealth: { descriptions: [], required: false },
                            environmentalFactors: { descriptions: [], required: false },
                            previousTherapy: {
                                description: '',
                                required: false,
                            },
                            careGiverEducation: {
                                description: '',
                                required: false,
                            },
                        },
                        medicalNecessity: {
                            therapyNecessity: {
                                description: '',
                                required: true,
                            },
                            interventionApproaches: {
                                description: '',
                                required: true,
                            },
                        },
                        patientAssessment: {
                            functionalDeficits: { required: true, description: [] as any },
                            impairments: { required: true, description: [] as any },
                            standardizedTests: { required: true, description: [] as any },
                        },
                        // temp: {
                        //   description: '',
                        //   required: false,
                        // },
                        progressNote: {
                            subjectiveAssessment: {
                                description: '',
                                required: true,
                            },
                            objectiveAssessment: {
                                description: '',
                                required: false,
                            },
                            plan: {
                                description: '',
                                required: false,
                            },
                        },
                        planOfCare: {
                            goal: {
                                description: '',
                                required: true,
                            },
                            frequencyAndDuration: {
                                description: [] as any,
                                required: true,
                            },
                            skilledServices: {
                                description: [] as any,
                                required: true,
                            },
                            certification: {
                                description: {
                                    certificationFrom: '',
                                    certificationThrough: '',
                                },
                                required: true,
                            },
                            // certificationFrom: {
                            //   description: '',
                            //   required: true,
                            // },
                            // certificationThrough: {
                            //   description: '',
                            //   required: true,
                            // },
                            goalPurpose: {
                                description: '',
                                required: true,
                            },

                            anticipatedDischarge: {
                                description: {
                                    anticipatedDischargePlans: '',
                                    anticipatedDischargeDestination: '',
                                },
                                required: true,
                            },
                            // anticipatedDischargePlans: {
                            //   description: '',
                            //   required: true,
                            // },
                            // anticipatedDischargeDestination: {
                            //   description: '',
                            //   required: true,
                            // },
                            temp: {
                                description: '',
                                required: false,
                            },
                            goals: [],
                        },
                        evaluationType: {
                            description: { description: '', evalType: '' },
                            required: true,
                        },
                    },

                    dailyNote: {
                        objectiveAssessment: {
                            description: {
                                respiratoryRate: '',
                                temperature: '',
                                pulseRate: '',
                                bloodPressure: '',
                                saturation: '',
                            },
                            required: false,
                        },
                        subjectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        caregiverEducation: {
                            description: '',
                            required: false,
                        },
                        assessment: {
                            description: { responseTreatment: '' },
                            required: false,
                        },
                        plan: {
                            description: '',
                            required: false,
                        },
                    },
                    progressNote: {
                        subjectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        objectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        plan: {
                            description: '',
                            required: false,
                        },
                    },
                    addendum: {
                        description: '',
                        required: false,
                    },
                    updatedPlanOfCareNote: {
                        subjectiveAssessment: {
                            description: '',
                            required: true,
                        },
                        objectiveAssessment: {
                            impairments: {
                                description: [],
                            },
                            functionalDeficits: {
                                description: [],
                            },
                            standardizedTests: {
                                description: [],
                            },
                        },
                    },
                    dischargeNote: {
                        subjectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        assessment: {
                            description: { clinicalImpression: '', endOfCareDate: '', endOfCareReason: '' },
                            required: false,
                        },
                        assessmentDischarge: {
                            description: { dischargeInstructions: '' },
                            required: true,
                        },
                        dischargeDestination: {
                            description: '',
                            required: false,
                        },
                        dischargePlans: {
                            description: '',
                            required: false,
                        },
                    },
                    reCertNote: {
                        subjectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        objectiveAssessment: {
                            impairments: [],
                            functionalDeficits: [],
                            standardizedTests: [],
                        },
                        assessment: {
                            description: {
                                clinicalImpression: '',
                                therapyNecessity: '',
                                prognosisAchievingRehabGoals: '',
                                reasonContinuingTreatment: '',
                                updatesToTreatment: '',
                                anticipatedDischargeDestination: '',
                                anticipatedDischargePlans: '',
                            },
                            required: false,
                        },
                    },
                };
                // this.updateDocumentation(this.currentTherapyAdmission);
            } else {
                this.currentTherapyAdmission.documentation = {
                    evaluation: {
                        medicalReview: {
                            reasonForReferral: {
                                description:
                                    this.currentTherapyAdmission.documentation.evaluation.medicalReview
                                        .reasonForReferral,
                                required: true,
                            },
                            chiefComplaint: {
                                description:
                                    this.currentTherapyAdmission.documentation.evaluation.medicalReview
                                        .reasonForReferral,
                                required: true,
                            },
                            precautions: { description: [], required: true },
                            contraindications: { description: [], required: true },
                            medications: { description: [], required: true },
                            treatmentDiagnosis: { description: [], required: true },
                            medicalDiagnosis: { description: [], required: true },
                        },
                        backgroundAssessment: {
                            patientGoals: { descriptions: [], required: false },
                            priorLevelOfFunction: {
                                description: '',
                                required: true,
                            },
                            socialDeterminantsOfHealth: { descriptions: [], required: false },
                            environmentalFactors: { descriptions: [], required: false },
                            previousTherapy: {
                                description: '',
                                required: false,
                            },
                            careGiverEducation: {
                                description: '',
                                required: false,
                            },
                        },
                        medicalNecessity: {
                            therapyNecessity: {
                                description:
                                    this.currentTherapyAdmission.documentation.evaluation.medicalNecessity
                                        .therapyNecessity,
                                required: true,
                            },
                            interventionApproaches: {
                                description:
                                    this.currentTherapyAdmission.documentation.evaluation.medicalNecessity
                                        .interventionApproaches,
                                required: true,
                            },
                        },
                        patientAssessment: {
                            functionalDeficits: { required: true, description: [] as any },
                            impairments: { required: true, description: [] as any },
                            standardizedTests: { required: true, description: [] as any },
                            // temp: {
                            //   description: '',
                            //   required: false,
                            // },
                        },
                        planOfCare: {
                            goal: {
                                description: '',
                                required: true,
                            },
                            frequencyAndDuration: {
                                description: [] as any,
                                required: true,
                            },
                            skilledServices: {
                                description: [] as any,
                                required: true,
                            },
                            certification: {
                                description: {
                                    certificationFrom: '',
                                    certificationThrough: '',
                                },
                                required: true,
                            },

                            // certificationFrom: {
                            //   description: '',
                            //   required: true,
                            // },
                            // certificationThrough: {
                            //   description: '',
                            //   required: true,
                            // },
                            goalPurpose: {
                                description: '',
                                required: true,
                            },
                            anticipatedDischarge: {
                                description: {
                                    anticipatedDischargePlans: '',
                                    anticipatedDischargeDestination: '',
                                },
                                required: true,
                            },
                            temp: {
                                description: '',
                                required: false,
                            },
                            goals: [],
                        },
                        evaluationType: {
                            description: { description: '', evalType: '' },
                            required: true,
                        },
                    },

                    dailyNote: {
                        objectiveAssessment: {
                            description: {
                                respiratoryRate: '',
                                temperature: '',
                                pulseRate: '',
                                bloodPressure: '',
                                saturation: '',
                            },
                            required: false,
                        },
                        subjectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        caregiverEducation: {
                            description: '',
                            required: false,
                        },
                        assessment: {
                            description: { responseTreatment: '' },
                            required: false,
                        },
                        plan: {
                            description: '',
                            required: false,
                        },
                    },
                    progressNote: {
                        subjectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        objectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        plan: {
                            description: '',
                            required: false,
                        },
                    },
                    addendum: {
                        description: '',
                        required: false,
                    },
                    updatedPlanOfCareNote: {
                        subjectiveAssessment: {
                            description: '',
                            required: true,
                        },
                        objectiveAssessment: {
                            impairments: {
                                description: [],
                            },
                            functionalDeficits: {
                                description: [],
                            },
                            standardizedTests: {
                                description: [],
                            },
                        },
                    },
                    dischargeNote: {
                        subjectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        assessment: {
                            description: { clinicalImpression: '', endOfCareDate: '', endOfCareReason: '' },
                            required: false,
                        },
                        assessmentDischarge: {
                            description: { dischargeInstructions: '' },
                            required: true,
                        },
                        dischargeDestination: {
                            description: '',
                            required: false,
                        },
                        dischargePlans: {
                            description: '',
                            required: false,
                        },
                    },
                    reCertNote: {
                        subjectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        objectiveAssessment: {
                            description: {
                                respiratoryRate: '',
                                temperature: '',
                                pulseRate: '',
                                bloodPressure: '',
                                saturation: '',
                            },
                            required: false,
                        },
                        assessment: {
                            description: {
                                clinicalImpression: '',
                                therapyNecessity: '',
                                prognosisAchievingRehabGoals: '',
                                reasonContinuingTreatment: '',
                                updatesToTreatment: '',
                                anticipatedDischargeDestination: '',
                                anticipatedDischargePlans: '',
                            },
                            required: false,
                        },
                    },
                };
                // this.updateDocumentation(this.currentTherapyAdmission);
            }
        }
    }

    // Fetches latest POC in action
    fetchLatestPoc(docId: string, date: string): Observable<any> {
        return this.http.get(`${baseUrl}/documentation/latest-poc/${docId}?date=${date}`);
    }

    getGoalTrends(id: string, goalAssessmentId: string, currentNoteId: string): Observable<any> {
        return this.http.get(`${baseUrl}/documentation/trends/${id}/${goalAssessmentId}/${currentNoteId}`);
    }

    // Updates individual daily note
    updateDailyNote(id: string, body: any): Observable<any> {
        return this.http.put(`${baseUrl}/dailyNote/${id}`, body);
    }

    // Updates individual progress note
    updateProgressNote(id: string, body: any): Observable<any> {
        return this.http.put(`${baseUrl}/progressNote/${id}`, body);
    }

    // Updates individual recert note
    updateRecertNote(id: string, body: any): Observable<any> {
        return this.http.put(`${baseUrl}/recertification/${id}`, body);
    }

    // Updates individual upoc note
    updateUpoc(id: string, body: any): Observable<any> {
        return this.http.put(`${baseUrl}/upoc/${id}`, body);
    }

    // Updates individual discharge note
    updateDischargeNote(id: string, body: any): Observable<any> {
        return this.http.put(`${baseUrl}/dischargeNote/${id}`, body);
    }

    // Updates individual evaluation
    updateEvaluation(id: string, body: any, queryParams?: EvaluationQueryParams): Observable<any> {
        if (queryParams) {
            return this.http.put(
                `${baseUrl}/evaluation/${id}?facilityId=${queryParams.facilityId}&integratedPatientId=${queryParams.integratedPatientId}&integratedOrganizationName=${queryParams.integratedOrganizationName}&integratedOrganizationId=${queryParams.integratedOrganizationId}`,
                body
            );
        } else {
            return this.http.put(`${baseUrl}/evaluation/${id}`, body);
        }
    }

    // Cascades latest poc data to other notes
    cascadeDatatoNotes(selectedNote: string, noteId: string, latestPoc: any) {
        let currentTherapyAdmission: TherapyAdmission = JSON.parse(JSON.stringify(this.currentTherapyAdmission));
        const allAdmissions = this.allTherapyAdmissions.filter((x) => x._id !== currentTherapyAdmission._id);
        return new Promise((resolve, reject) => {
            switch (selectedNote) {
                case DOCUMENTATION_CONSTANTS.DAILY: {
                    const previousDailyNote = currentTherapyAdmission.documentation.dailyNote.find(
                        (dn) => dn.id === noteId
                    );
                    const indexOfFoundDailyNote = currentTherapyAdmission.documentation.dailyNote.findIndex(
                        (dn) => dn.id === noteId
                    );
                    const body = {
                        patientHistory: {
                            ...previousDailyNote.patientHistory,
                            precautions: latestPoc.precautions,
                            contraindications: latestPoc.contraindications,
                            medicalDiagnosis: latestPoc.medicalDiagnosis,
                            treatmentDiagnosis: latestPoc.treatmentDiagnosis,
                        },
                        objectiveAssessment: {
                            ...previousDailyNote.objectiveAssessment,
                            goals: latestPoc.goals,
                            skilledServices: latestPoc.skilledServices,
                            previousCptCodes: latestPoc.detailedCptCodes,
                            anticipatedDischargeDate: latestPoc.anticipatedDischargeDate,
                        },
                        plan: {
                            ...previousDailyNote.plan,
                            previousPlanForNextTreatment: latestPoc.planForNextTreatment,
                        },
                    };

                    const updatedDailyNote = { ...previousDailyNote, ...body } as any;
                    this.updateDailyNote(noteId, updatedDailyNote).subscribe({
                        next: (result) => {
                            result.data.note.assignedTherapists = updatedDailyNote.assignedTherapists;
                            currentTherapyAdmission.documentation.dailyNote[indexOfFoundDailyNote] = result.data.note;
                            allAdmissions.push(currentTherapyAdmission);
                            this.store.dispatch(
                                therapyadmissionChangedAction({
                                    therapyadmission: allAdmissions,
                                })
                            );
                        },
                        error: () => {
                            reject();
                        },
                        complete: () => {
                            resolve(true);
                        },
                    });
                    break;
                }
                case DOCUMENTATION_CONSTANTS.PROGRESS: {
                    const previousProgressNote = currentTherapyAdmission.documentation.progressNote.find(
                        (pn) => pn.id === noteId
                    );
                    const indexOfFoundProgressNote = currentTherapyAdmission.documentation.progressNote.findIndex(
                        (pn) => pn.id === noteId
                    );
                    const body = {
                        patientHistory: {
                            ...previousProgressNote.patientHistory,
                            precautions: latestPoc.precautions,
                            contraindications: latestPoc.contraindications,
                            medicalDiagnosis: latestPoc.medicalDiagnosis,
                            treatmentDiagnosis: latestPoc.treatmentDiagnosis,
                        },
                        objectiveAssessment: {
                            goals: latestPoc.goals,
                        },
                    };
                    const updatedProgressNote = { ...previousProgressNote, ...body } as any;
                    if (!updatedProgressNote?.signed?.signDate) {
                        updatedProgressNote.objectiveAssessment.goals?.forEach((goal) => {
                            goal.updatedTargetDate = '';
                            goal.stgs?.forEach((stg) => {
                                stg.updatedTargetDate = '';
                            });
                        });
                    }

                    this.updateProgressNote(noteId, updatedProgressNote).subscribe({
                        next: (result) => {
                            result.data.note.assignedTherapists = updatedProgressNote.assignedTherapists;
                            currentTherapyAdmission.documentation.progressNote[indexOfFoundProgressNote] =
                                result.data.note;
                            currentTherapyAdmission = result.data.therapyAdmission;
                            allAdmissions.push(currentTherapyAdmission);
                            this.store.dispatch(
                                therapyadmissionChangedAction({
                                    therapyadmission: allAdmissions,
                                })
                            );
                        },
                        error: () => {
                            reject();
                        },
                        complete: () => {
                            resolve(true);
                        },
                    });
                    break;
                }
                case DOCUMENTATION_CONSTANTS.RECERTIFICATION: {
                    const previousRecertNote = currentTherapyAdmission.documentation.recertificationNote.find(
                        (pn) => pn.id === noteId
                    );
                    const indexOfFoundRecertNote = currentTherapyAdmission.documentation.recertificationNote.findIndex(
                        (pn) => pn.id === noteId
                    );
                    const body = {
                        patientHistory: {
                            ...previousRecertNote.patientHistory,
                            precautions: latestPoc.precautions,
                            contraindications: latestPoc.contraindications,
                            medicalDiagnosis: latestPoc.medicalDiagnosis,
                            treatmentDiagnosis: latestPoc.treatmentDiagnosis,
                        },
                        objectiveAssessment: {
                            impairments: latestPoc.patientAssessment.impairments,
                            functionalDeficits: latestPoc.patientAssessment.functionalDeficits,
                            standardizedTests: latestPoc.patientAssessment.standardizedTests,
                        },
                        plan: {
                            goals: latestPoc.goals,
                            skilledServices: latestPoc.skilledServices,
                            frequencyAndDuration: latestPoc.frequencyAndDuration,
                        },
                    };
                    const updatedRecertNote = { ...previousRecertNote, ...body } as any;
                    this.updateRecertNote(noteId, updatedRecertNote).subscribe({
                        next: (result) => {
                            result.data.note.assignedTherapists = updatedRecertNote.assignedTherapists;
                            currentTherapyAdmission.documentation.recertificationNote[indexOfFoundRecertNote] =
                                result.data.note;
                            allAdmissions.push(currentTherapyAdmission);
                            this.store.dispatch(
                                therapyadmissionChangedAction({
                                    therapyadmission: allAdmissions,
                                })
                            );
                        },
                        error: () => {
                            reject();
                        },
                        complete: () => {
                            resolve(true);
                        },
                    });
                    break;
                }
                case DOCUMENTATION_CONSTANTS.UPOC: {
                    const previousUpocNote = currentTherapyAdmission.documentation.updatedPlanOfCareNote.find(
                        (pn) => pn.id === noteId
                    );
                    const indexOfFoundUpocNote = currentTherapyAdmission.documentation.updatedPlanOfCareNote.findIndex(
                        (pn) => pn.id === noteId
                    );
                    const body = {
                        patientHistory: {
                            ...previousUpocNote.patientHistory,
                            precautions: latestPoc.precautions,
                            contraindications: latestPoc.contraindications,
                            medicalDiagnosis: latestPoc.medicalDiagnosis,
                            treatmentDiagnosis: latestPoc.treatmentDiagnosis,
                        },
                        objectiveAssessment: {
                            impairments: latestPoc.patientAssessment.impairments,
                            functionalDeficits: latestPoc.patientAssessment.functionalDeficits,
                            standardizedTests: latestPoc.patientAssessment.standardizedTests,
                        },
                        plan: {
                            goals: latestPoc.goals,
                            skilledServices: latestPoc.skilledServices,
                            frequencyAndDuration: latestPoc.frequencyAndDuration,
                        },
                    };
                    const updatedRecertNote = { ...previousUpocNote, ...body } as any;
                    this.updateUpoc(noteId, updatedRecertNote).subscribe({
                        next: (result) => {
                            result.data.assignedTherapists = updatedRecertNote.assignedTherapists;
                            currentTherapyAdmission.documentation.updatedPlanOfCareNote[indexOfFoundUpocNote] =
                                result.data.note;
                            allAdmissions.push(currentTherapyAdmission);
                            this.store.dispatch(
                                therapyadmissionChangedAction({
                                    therapyadmission: allAdmissions,
                                })
                            );
                        },
                        error: () => {
                            reject();
                        },
                        complete: () => {
                            resolve(true);
                        },
                    });
                    break;
                }
                case DOCUMENTATION_CONSTANTS.DISCHARGE: {
                    const dischargeNote = currentTherapyAdmission.documentation.dischargeNote as any;
                    dischargeNote.goalsStatus = latestPoc.goals;
                    dischargeNote.patientHistory.medicalDiagnosis = latestPoc.medicalDiagnosis;
                    dischargeNote.patientHistory.treatmentDiagnosis = latestPoc.treatmentDiagnosis;
                    dischargeNote.objectiveAssessment.functionalDeficits = this.mapDeficitItemsAtDischarge(
                        latestPoc.patientAssessment.functionalDeficits
                    ) as any;
                    dischargeNote.objectiveAssessment.ggItems = [] as any;
                    this.updateDischargeNote(noteId, dischargeNote).subscribe({
                        next: (result) => {
                            result.data.assignedTherapists = dischargeNote.assignedTherapists;
                            currentTherapyAdmission.documentation.dischargeNote = result.data;
                            allAdmissions.push(currentTherapyAdmission);
                            this.store.dispatch(
                                therapyadmissionChangedAction({
                                    therapyadmission: allAdmissions,
                                })
                            );
                        },
                        error: () => {
                            reject();
                        },
                        complete: () => {
                            resolve(true);
                        },
                    });
                    break;
                }
                case DOCUMENTATION_CONSTANTS.EVALUATION: {
                    resolve(true);
                    break;
                }
                case DOCUMENTATION_CONSTANTS.ADDENDUM: {
                    resolve(true);
                    break;
                }
            }
        });
    }

    mapDeficitItemsAtDischarge(functionalDeficits: any[]) {
        return functionalDeficits.map((fd) => {
            return {
                id: fd._id,
                name: fd.name,
                hasGGScore: fd.clof.ggScore ? true : false,
                hasAssistiveDevice: fd.clof.assistiveDevice ? true : false,
                scale: fd.scale.scaleDetails.name,
                clof: {
                    assistiveDevice: '',
                    ggScore: '',
                    levelOfFunction: '',
                },
            };
        });
    }

    setDocPrintLayoutStatus(isOpened: boolean): void {
        this.docPrintLayoutStatus.next(isOpened);
    }

    setSignatureProcessStatus(isSigning: boolean): void {
        this.signatureProcessStatus = isSigning;
    }
    setSortedCaseload(state) {
        this.sortStatus = state;
    }
    getSortedCaseload() {
        return this.sortStatus;
    }
}
