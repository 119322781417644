import { Component, EventEmitter, Output } from '@angular/core';
import { GridActionConstants } from '@app/data-grid/interfaces/grid-row-action';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { FacilityManagementService } from '@app/services';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-switch-cell-renderer',
    templateUrl: './switch-cell-renderer.component.html',
    styleUrls: ['./switch-cell-renderer.component.scss'],
})
export class SwitchCellRendererComponent implements ICellRendererAngularComp {
    @Output() toggleChanged = new EventEmitter<boolean>();
    switchValue: boolean;
    params;
    radioId: string;

    constructor(
        private gridActionService: GridActionsService,
        private facilityManagementService: FacilityManagementService
    ) {}

    agInit(params: ICellRendererParams): void {
        this.switchValue = params.value;
        this.params = params;
        this.params = params;
        params.data?.impairmentName
            ? (this.radioId = params.data.impairmentName + params.data.fieldName + params.data.subSection)
            : params.data.name
            ? (this.radioId = params.data.name)
            : this.params.colName && (params.data._id || params.data.id)
            ? (this.radioId = this.params.colName + (params.data._id || params.data.id))
            : (params.data._id || params.data.id)
            ? (this.radioId = (params.data._id || params.data.id))
            : (this.radioId = 'defaultId');
    }
    refresh(): boolean {
        return true;
    }


    onSwitchValue(): void {
        if (this.params.gridName && ( this.params.gridName == 'users' || this.params.gridName == 'facilities')) {
            this.params.value = this.params.data.isActive = this.switchValue;
            const { data, rowIndex, gridName } = this.params;
            this.gridActionService.setSelectedAction(GridActionConstants.STATUS_CHANGED, data, rowIndex, gridName);
        } else if (this.params.colName && this.params.colName == 'co-sign') {
            this.params.value = this.params.data.isActive = this.switchValue;
            const { data, rowIndex, colName } = this.params;
            this.gridActionService.setSelectedAction(`${this.params.colName}`, data, rowIndex, colName);
        }
        if (this.params && this.params.gridName == 'manage-recipients') {
            this.params.value = this.params.data.isActive = this.switchValue;
            const { data, rowIndex, gridName } = this.params;
            this.gridActionService.setSelectedAction(this.params.value, data, rowIndex, gridName);
        } else if (Object.prototype.hasOwnProperty.call(this.params.data, 'enable')) {
            this.params.value = this.params.data.enable = this.switchValue;
            const { data, rowIndex, colName } = this.params;
            this.gridActionService.setSelectedAction(`${this.params.colName}`, data, rowIndex, colName);
            this.params.toggleChanged(this.switchValue);
        } else if (this.params.gridName && this.params.gridName == 'base-roles') {
            this.params.value = this.params.data.display = this.switchValue;
            const { data, rowIndex, gridName } = this.params;
            this.gridActionService.setSelectedAction(GridActionConstants.STATUS_CHANGED, data, rowIndex, gridName);
        } else {
            this.params.value = this.params.data.isAGoal = this.switchValue;
            const { data, rowIndex, gridName } = this.params;
            this.gridActionService.setSelectedAction(GridActionConstants.MAKE_A_GOAL, data, rowIndex, gridName);
        }
    }
}
