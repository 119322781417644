<app-base-modal-card
    [showFooter]="false"
    [heading]="content.heading"
    [wrapperClass]="content.heading === 'Approve Hours' ? 'modal-time' : 'modal-medium'"
    (close)="closeAction(false)"
>
    <div body class="rounded text-center">
        <!-- <span *ngIf="content.icon" class="material-symbols-outlined icon-class" [class.filled]="content.iconFilled">
            {{ content.icon }}
        </span> -->
        <div class="p-2">
            <p class="d-flex flex-row justify-content-center body-medium text-secondary text-left body-text mt-1">
                {{ content.bodyText }}
            </p>

            <div class="d-flex justify-content-start">
                <div class="show-again-layout text-left">
                    <app-checkbox
                    class="fw-600"
                        [(ngModel)]="showAgain"
                        (optionChecked)="onCheckboxChange($event)"
                        [options]="[
                            {
                                text: account?.user?.lastName + ', ' + account?.user?.firstName + ' - ' + account?.user?.physician?.credentials,
                                isChecked: content.showAgain
                            }
                        ]"
                    ></app-checkbox>
                </div>
            </div>
            <hr />
            <div class="d-flex justify-content-end">
                <button
                    [disabled]="!isCheck"
                    class="btn btn-secondary innova-btn btn-outlined"
                    (click)="confirmAction(true)"
                >
                    {{ content.btn2Text }}
                </button>
            </div>
        </div>
    </div>
</app-base-modal-card>
