import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, shareReplay } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Patient, patientFilters, patientMenuEvents, User } from '@app/models';
import { Store } from '@ngrx/store';
import { patientChangedAction } from '@app/store';
import { PatientState } from '@app/store/store.interface';
import { StoreService } from './store.service';
import { ROUTER_UTILS } from '@app/helpers';
import { ColDef } from 'ag-grid-community';
import { format } from 'date-fns';
import { FacilityManagementService } from './facilityManagement.service';
import { getCurrentUser } from '@app/helpers/constants/documentation-report';
import { OrganizationManagementService } from './organizationManagement.service';

const baseUrl = `${environment.apiUrl}/patient`;

@Injectable({ providedIn: 'root' })
export class PatientService {
    private patientSubject: BehaviorSubject<Patient>;
    public _patient: Observable<Patient>;
    public detailViewHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public patientDetailHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    user: User;

    private patientFilters$: BehaviorSubject<patientFilters>;
    private patientMenuEvents$: BehaviorSubject<patientMenuEvents>;
    // needed to find past facility admission to limit new admit date restriction
    searchPatientResponse: [] = null;
    selectedPatientMRN = null;
    selectedPatientFacility = null;

    tab$: Observable<any>;
    constructor(
        private router: Router,
        private http: HttpClient,
        private store: Store<{ patient: PatientState }>,
        private storeService: StoreService,
        private facilityManagementService: FacilityManagementService,
        private organizationManagementService: OrganizationManagementService
    ) {
        this.user = getCurrentUser();
        this.patientFilters$ = new BehaviorSubject({
            therapyDiscipline: 'ALL',
            active: 'ALL',
            payor: 'ALL',
            search: '',
            patientType: 'ALL',
            therapyStatus: 'ALL',
        });
        this.patientMenuEvents$ = new BehaviorSubject({
            export: false,
            print: false,
        });
        this.patientSubject = new BehaviorSubject<Patient>(null);
        this._patient = this.patientSubject.asObservable();
        // this.tab$ = store.pipe(select('patient'));
    }

    public setSelectedPatientData(id: any, facilityId: string | null = null) {
        // debugger;
        facilityId = facilityId ? facilityId : this.facilityManagementService._currentFacility.id;
        const currentSelectedPatient: any = this.searchPatientResponse.find((patient: any) => patient._id === id);
        console.log({ currentSelectedPatient });

        if (
            currentSelectedPatient &&
            currentSelectedPatient.facilityAdmissions &&
            currentSelectedPatient.facilityAdmissions.length
        ) {
            // Filter by specified facility and check patientDischarge field
            const filteredAdmissions = currentSelectedPatient.facilityAdmissions
                .filter(
                    (admission: any) =>
                        admission.facility === facilityId &&
                        (!admission.patientDischarge ||
                            admission.patientDischarge === null ||
                            admission.patientDischarge === undefined)
                )
                .sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

            const sortedAdmissions = currentSelectedPatient.facilityAdmissions
                .filter((admission: any) => admission.facility === facilityId)
                .sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

            this.selectedPatientMRN = filteredAdmissions.length
                ? filteredAdmissions[0]?.MRN
                : sortedAdmissions.length
                ? sortedAdmissions[0]?.MRN
                : null;

            this.selectedPatientFacility = sortedAdmissions.length ? sortedAdmissions[0].facility : null;
        } else {
            this.selectedPatientMRN = null;
            this.selectedPatientFacility = null;
        }
    }

    public getPatientMRN(): string {
        return this.selectedPatientMRN;
    }

    public getPatientFacility(): string {
        return this.selectedPatientFacility;
    }

    public get patientValue(): Patient {
        return this.patientSubject.value;
    }

    set patientFilters(val: Observable<patientFilters>) {
        val.subscribe((data) => {
            this.patientFilters$.next(data);
        });
    }

    get patientFilters(): Observable<patientFilters> {
        return this.patientFilters$.asObservable();
    }
    set patientMenuEvents(val: Observable<patientMenuEvents>) {
        val.subscribe((data) => {
            this.patientMenuEvents$.next(data);
        });
    }

    get patientMenuEvents(): Observable<patientMenuEvents> {
        return this.patientMenuEvents$.asObservable();
    }
    personalInformationSave(patientData: Patient) {
        return this.http.post<any>(`${baseUrl}/personal-info`, patientData, { withCredentials: true }).pipe(
            map((patient) => {
                // this.patientRemovedService();
                this.store.dispatch(patientChangedAction({ patient: patient.data }));
                this.storeService.admissionStepper = ROUTER_UTILS.config.addPatients.facilityAdmission;

                // this.router.navigate([ROUTER_UTILS.config.addPatients.editAdmission], { skipLocationChange: true });
                this.patientSubject.next(patient.data);
                return patient.data;
            })
        );
    }
    patientUpdate(patientData: Patient) {
        this.storeService
            .getPatientState()
            .pipe(take(1))
            .subscribe((data) => {
                patientData._id = data.id;
            });
        return this.http.patch<any>(`${baseUrl}/personal-info`, patientData, { withCredentials: true }).pipe(
            map((patient) => {
                // this.patientRemovedService();
                this.storeService.admissionStepper = ROUTER_UTILS.config.addPatients.facilityAdmission;
                const tempP: PatientState = { id: patient.data.id, ...patientData };
                this.store.dispatch(patientChangedAction({ patient: tempP }));
                this.patientSubject.next(patient.data);
                return patient.data;
            })
        );
    }
    patientUpdateWithFiles(patientData: Patient) {
        return this.http.patch<any>(`${baseUrl}/personal-info/files`, patientData, { withCredentials: true }).pipe(
            map((patient) => {
                // this.patientRemovedService();
                const tempP: PatientState = { id: patient.data.id, ...patientData };
                this.patientSubject.next(patient.data);
                this.store.dispatch(patientChangedAction({ patient: tempP }));
                return patient.data;
            })
        );
    }

    searchPatient(text: string) {
        const orgId: string = this.organizationManagementService?._currentOrganization?.id ?? '';
        return this.http
            .get<{ data: PatientState[] }>(`${baseUrl}/search/${text}/${orgId}`, { withCredentials: true })
            .pipe(
                map((patient) => {
                    // this.store.dispatch(patientChangedAction({ patient: patient.data }));
                    // this.patientSubject.next(patient.data);
                    return patient.data;
                })
            );
    }
    checkPatientSSN(SSN: string) {
        const organizationId = this.organizationManagementService._currentOrganization._id || this.organizationManagementService._currentOrganization.id;
        return this.http.get<{ data: boolean }>(`${baseUrl}/check-ssn/` + SSN + `?organization=${organizationId}`, { withCredentials: true }).pipe(
            map((patient) => {
                return patient.data;
            })
        );
    }

    // getPatientBySSN(ssn: string) {
    //   return this.http.post<any>(`${baseUrl}/search`, { SSN: ssn }, { withCredentials: true }).pipe(
    //     map((patient) => {
    //       // this.patientRemovedService();
    //       // this.store.dispatch(patientChangedAction({ patient: patient.data }));
    //       this.patientSubject.next(patient.data);
    //       this.storeService
    //         .initializePatientData(patient.data.id)
    //         .pipe(take(1))
    //         .subscribe((data) => {});
    //       return patient.data;
    //     })
    //   );
    // }
    // patientRemovedService() {
    //   this.storeService.resetPatientState();
    //   this.storeService.resetFacilityAdmissionState();
    //   this.storeService.resetTherapyAdmissionState();
    // }

    getPatientListHeaders(): ColDef[] {
        return [
            {
                headerName: 'Name',
                resizable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc'],
                valueGetter: (v) => {
                    if (v?.data === undefined) return '';
                    const patientName =
                        v.data.lastName[0].toUpperCase() +
                        v.data.lastName.slice(1).toLowerCase() +
                        ', ' +
                        v.data.firstName[0].toUpperCase() +
                        v.data.firstName.slice(1).toLowerCase();
                    return patientName;
                },
                colId: 'name',
            },
            {
                headerName: 'MRN',
                resizable: true,

                valueGetter: (v) => {
                    if (v?.data === undefined) return '';
                    return v.data.facilityAdmission.MRN ? v.data.facilityAdmission.MRN : '';
                },
                width: 100,
            },

            // {
            //     headerName: 'Room No.',
            //     field: 'roomNumber',
            //     valueGetter: (v) => {
            //         if (v?.data === undefined) return '';
            //         return v.data.facilityAdmission?.roomNumber;
            //     },
            // },
            {
                headerName: 'Discipline',
                field: 'discipline',
                cellRenderer: (params) => {
                    if (params?.data === undefined) return '';
                    let dis = [];
                    const dischargedDis = [];
                    params.data.facilityAdmission.therapyAdmission?.map((r) => {
                        if (r.isDischarged) dischargedDis.push(r.therapyDiscipline);
                        else dis.push({ discipline: r.therapyDiscipline, isActive: true });
                    });

                    dischargedDis.forEach((discipline) => {
                        if (!dis.map((d) => d.discipline).includes(discipline)) {
                            dis.push({ discipline: discipline, isActive: false });
                        }
                    });
                    dis = dis.sort((a, b) => {
                        return ['PT', 'OT', 'ST'].indexOf(a.discipline) - ['PT', 'OT', 'ST'].indexOf(b.discipline);
                    });
                    const tags = dis.map((d) => {
                        if (d.isActive) return `<span>${d.discipline}</span>`;
                        else return `<span style="color: #A5B5D9">${d.discipline}</span>`;
                    });
                    return `<span>${tags.length > 0 ? tags.join(',') : '--'}</span>`;
                },
            },

            {
                headerName: 'Type',
                field: 'admissionType',
                valueGetter: (v) => {
                    if (v?.data === undefined) return '';
                    return v.data.facilityAdmission?.admissionType;
                },
            },
            {
                headerName: 'Physician',
                field: 'attendingPhysicians',
                resizable: true, // for time being
                valueFormatter: this.physicianNameFormatter,
            },
            {
                headerName: 'Payor',
                valueGetter: (v) => {
                    if (v?.data === undefined) return '';
                    return v.data.payor ? v.data.payor.name : '';
                },
            },
            {
                headerName: 'Admit Date',
                field: 'admitDate',
                valueGetter: (v) => {
                    if (v?.data === undefined) return '';
                    return v.data.facilityAdmission?.admitDate
                        ? format(new Date(v.data.facilityAdmission?.admitDate), 'MM/dd/yyyy')
                        : 'No Facility Admission';
                },
                comparator: (valueA, valueB) => {
                    /**
                     * Parsed Date because it was comparing strings previously
                     */
                    if (valueA == 'No Facility Admission') {
                        valueA = null;
                    }
                    if (valueB == 'No Facility Admission') {
                        valueB = null;
                    }
                    // Only comparing those patients that have admit date otherwise it was causing issue in sorting
                    valueA = valueA ? new Date(valueA) : '';
                    valueB = valueB ? new Date(valueB) : '';

                    if (valueA === valueB) return 0;

                    return valueA > valueB ? 1 : -1;
                },
                sortable: true,
                sortingOrder: ['asc', 'desc'],
            },

            // {
            //     headerName: 'Facility Status',
            //     field: 'status',
            //     valueGetter: (v) => {
            //         if (v?.data === undefined) return '';
            //         return v.data.facilityAdmission?.patientDischarge &&
            //             v.data.facilityAdmission?.patientDischarge?.facilityDischargeDate
            //             ? format(
            //                   new Date(v.data.facilityAdmission?.patientDischarge?.facilityDischargeDate),
            //                   'MM/dd/yyyy'
            //               )
            //             : 'Active';
            //     },
            // },
        ];
    }

    getAllPatients(filters: any, page: number, pageSize: number, sortField: string, sortOrder: string) {
        return this.http
            .get<any>(
                `${baseUrl}/all/${this.facilityManagementService._currentFacility.id}?pageNumber=${page}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}&patientType=${filters.patientType}&patientDischargeStatus=${filters.patientDischargeStatus}&patientName=${filters.patientName}&therapyDiscipline=${filters.therapyDiscipline}&payorName=${filters.payorName}&therapyStatus=${filters.therapyStatus}`,
                { withCredentials: true }
            )
            .pipe(take(1), shareReplay());
    }
    getPatientWhiteboard(date: string) {
        return this.http.post<any>(`${baseUrl}/white-board`, { date }).pipe(take(1));
    }
    getPatientAdmissionHistory(id: string, facilityId: string) {
        return this.http
            .get<any>(`${baseUrl}/history/${id}?facilityId=${facilityId}`, { withCredentials: true })
            .pipe(take(1), shareReplay());
    }

    getPatientBoardList() {
        return this.http
            .get<any>(`${baseUrl}/all/board/` + this.facilityManagementService._currentFacility.id, {
                withCredentials: true,
            })
            .pipe(take(1), shareReplay());
    }

    physicianNameFormatter(params: any) {
        if (params?.data === undefined) return '';
        let Physicians = '';
        const physician = params.data.attendingPhysicians?.map((r) => r);
        if (physician.length > 1) {
            for (let i = 0; i < physician.length; i++) {
                if (physician[i] !== undefined) {
                    if (i == physician.length - 1) {
                        Physicians = Physicians + physician[i];
                    } else Physicians = Physicians + physician[i] + ' | ';
                }
            }
        } else {
            Physicians = physician;
        }
        return Physicians;
    }
}
