<ul
    *ngIf="!hideNavbarForMobSize"
    ngbNav
    #nav="ngbNav"
    class="innova-navbar navbar-default nav-pills d-flex flex-column justify-content-start flex-nowrap bg-trasnparent pb-2"
    orientation="vertical"
    style="z-index: 1001"
>
    <!-- ========================== -->
    <!-- Dashboard  -->
    <!-- ========================== -->
    <li
        *ngIf="navigationPermission?.dashboard?.allowed"
        class="sidebar-item mt-2 p-4"
        ngbNavItem="dashboard"
        ngbDropdown
        [class.active]="isActive(['/home', 'dashboard'])"
    >
        <span ngbDropdownToggle class="sidebar-item-inner pt-1 pb-1" ngbNavItem="dashboard" id="dropdownBasic1">
            <span class="icon icon-dashboard sidebar-icon-size p-2 icon-circle-nav rounded-circle"> </span>
            <span class="text mt-1">{{ 'appTranslation.left_nav.dashboard' | translate | titlecase }}</span>
        </span>
        <div
            ngbDropdownMenu
            class="sidebar-dropdown ml-5 mt-n5 position-absolute sub-navbar p-0"
            aria-labelledby="dropdownBasic1"
        >
            <button
                *ngIf="navigationPermission?.dashboard?.therapistDashboard?.allowed"
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="therapistDashboardUrl"
                [routerLinkActive]="['active']"
            >
                <span class="material-icons-outlined sidebar-icon-size position-absolute"> dashboard </span>
                <span class="ml-2 pl-3">Therapist Dashboard</span>
            </button>
            <button
                *ngIf="navigationPermission.dashboard?.operationalDashboard?.allowed"
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="dorDashboardUrl"
                [routerLinkActive]="['active']"
            >
                <span class="material-icons-outlined sidebar-icon-size position-absolute"> view_quilt </span>
                <span class="ml-2 pl-3">Operations Dashboard</span>
            </button>
        </div>
    </li>

    <!-- ========================== -->
    <!-- Admission  -->
    <!-- ========================== -->
    <li
        *ngIf="navigationPermission?.admissions?.allowed"
        class="sidebar-item mt-1 p-4"
        ngbNavItem="add_patient"
        [routerLink]="addPatientUrl"
        [routerLinkActive]="['active']"
        (click)="destroyCurrentPatientState()"
    >
        <span ngbNavLink class="sidebar-item-inner pt-1 pb-1" ngbNavItem="admission">
            <span class="icon icon-add sidebar-icon-size p-2 icon-circle-nav rounded-circle"> </span>
            <span class="text mt-1">{{ 'appTranslation.left_nav.admission' | translate | titlecase }}</span>
        </span>
    </li>

    <!-- ========================== -->
    <!-- Patients  -->
    <!-- ========================== -->
    <li
        *ngIf="navigationPermission?.patients?.allowed"
        ngbDropdown
        class="sidebar-item mt-1 position-relative"
        ngbNavItem="Patients"
        [class.active]="isActive(['/pcc', '/patients', '/documentation'])"
    >
        <span ngbDropdownToggle class="sidebar-item-inner pt-1 pb-1" ngbNavItem="patients" id="dropdownBasic1">
            <span class="icon icon-user sidebar-icon-size p-2 icon-circle-nav rounded-circle"> </span>
            <span class="text mt-1">{{ 'appTranslation.left_nav.patients' | translate | titlecase }}</span>
        </span>
        <div
            ngbDropdownMenu
            class="sidebar-dropdown ml-5 mt-n5 position-absolute sub-navbar p-0"
            aria-labelledby="dropdownBasic1"
        >
            <button
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="patientListUrl"
                [routerLinkActive]="['active']"
            >
                <span class="material-icons-outlined sidebar-icon-size position-absolute"> format_list_bulleted </span>
                <span class="ml-2 pl-3">Patient List</span>
            </button>
            <button
                *ngIf="navigationPermission?.patients?.patientBoard?.allowed"
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLinkActive]="['active']"
                [routerLink]="patientBoardUrl"
            >
                <span class="material-icons-outlined sidebar-icon-size position-absolute"> space_dashboard </span>
                <span class="ml-2 pl-3">Patient Board</span>
            </button>
            <button
                *ngIf="navigationPermission?.patients?.pdpmBoard?.allowed"
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLinkActive]="['active']"
                [routerLink]="pdpmBoardUrl"
            >
                <span class="material-icons-outlined sidebar-icon-size position-absolute side-bar-icon-reverse">
                    insert_chart
                </span>
                <span class="ml-2 pl-3">PDPM Board</span>
            </button>
        </div>
    </li>

    <!-- ========================== -->
    <!-- Planner  -->
    <!-- ========================== -->
    <li
        *ngIf="navigationPermission?.planPatient?.allowed"
        ngbDropdown
        class="sidebar-item mt-1 position-relative"
        ngbNavItem="scheduling"
        [class.active]="isActive(['/scheduling'])"
    >
        <span ngbDropdownToggle class="sidebar-item-inner pt-1 pb-1" ngbNavItem="scheduling" id="dropdownBasic1">
            <span class="icon icon-time sidebar-icon-size p-2 icon-circle-nav rounded-circle"> </span>
            <span class="text mt-1">Plan Patient</span>
        </span>
        <div
            ngbDropdownMenu
            class="sidebar-dropdown ml-5 mt-n5 position-absolute sub-navbar p-0"
            aria-labelledby="dropdownBasic1"
        >
            <button
                *ngIf="navigationPermission?.planPatient?.planner?.allowed"
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="plannerUrl"
                [routerLinkActive]="['active']"
            >
                <span class="material-icons-outlined sidebar-icon-size position-absolute"> event_note </span>
                <span class="ml-2 pl-3">Planner</span>
            </button>
            <button
                *ngIf="navigationPermission?.planPatient?.scheduler?.allowed"
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLinkActive]="['active']"
                [routerLink]="schedulerV2Url"
            >
                <span class="material-icons-outlined sidebar-icon-size position-absolute"> content_paste_go </span>
                <span class="ml-2 pl-3">Scheduler</span>
            </button>
            <button
                *ngIf="navigationPermission?.planPatient?.whiteBoard?.allowed"
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLinkActive]="['active']"
                [routerLink]="whiteBoardUrl"
            >
                <span class="material-icons-outlined sidebar-icon-size position-absolute"> content_paste_go </span>
                <span class="ml-2 pl-3">Whiteboard</span>
            </button>
        </div>
    </li>

    <!-- ========================== -->
    <!-- Labor Management  -->
    <!-- ========================== -->
    <li
        *ngIf="navigationPermission?.labor?.allowed"
        ngbDropdown
        class="sidebar-item mt-1 position-relative"
        ngbNavItem="Labor History"
        [class.active]="isActive(['/labor']) || isActive(['/time-tracking'])"
    >
        <span ngbDropdownToggle class="sidebar-item-inner pt-1 pb-1" ngbNavItem="labor" id="dropdownBasie1">
            <span class="icon icon-time sidebar-icon-size p-2 icon-circle-nav rounded-circle"> </span>
            <span class="text mt-1" style="white-space: nowrap">Labor</span>
        </span>
        <div
            ngbDropdownMenu
            class="sidebar-dropdown ml-5 mt-n5 position-absolute sub-navbar p-0"
            aria-labelledby="dropdownBasie1"
            style="margin-top: -250px important; z-index: 1050 !important"
        >
            <button
                *ngIf="navigationPermission?.labor?.timeCards?.allowed"
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLinkActive]="['active']"
                [routerLink]="laborHistoryUrl"
            >
                <span class="material-icons-outlined sidebar-icon-size position-absolute"> content_paste_go </span>
                <span class="ml-2 pl-3">Timecards</span>
            </button>
            <button
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLinkActive]="['active']"
                [routerLink]="laborManagementUrl"
                *ngIf="navigationPermission?.labor?.therapistAvailability?.allowed"
            >
                <span class="material-icons-outlined sidebar-icon-size position-absolute"> content_paste_go </span>
                <span class="ml-2 pl-3">Labor Management</span>
            </button>
        </div>
    </li>

    <!-- ========================== -->
    <!-- System Admin  -->
    <!-- ========================== -->
    <li
        *ngIf="navigationPermission?.admin?.allowed"
        ngbDropdown
        class="sidebar-item mt-1 position-relative"
        ngbNavItem="facility"
        [class.active]="isActive([facilityUrl, usersUrl, rightsUrl, resourcesUrl, rolesUrl, organizationUrl, payorUrl])"
    >
        <span ngbDropdownToggle class="sidebar-item-inner pt-1 pb-1" ngbNavItem="facility" id="dropdownBasic1">
            <span class="icon-settings material-symbols-outlined sidebar-icon-size p-2 icon-circle-nav rounded-circle">
            </span>
            <span class="text mt-1">Admin</span>
        </span>
        <div
            ngbDropdownMenu
            class="sidebar-dropdown ml-5 mt-n5 position-absolute sub-navbar p-0"
            aria-labelledby="dropdownBasic1"
        >
            <button
                *ngIf="navigationPermission?.admin?.organization?.allowed"
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="organizationUrl"
                [routerLinkActive]="['active']"
            >
                <span class="material-icons-outlined sidebar-icon-size position-absolute"> corporate_fare </span>
                <span class="ml-2 pl-3">Organization</span>
            </button>
            <button
                *ngIf="navigationPermission?.admin?.facilities?.allowed"
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="facilityUrl"
                [routerLinkActive]="['active']"
            >
                <span class="material-icons-outlined sidebar-icon-size position-absolute"> home_work </span>
                <span class="ml-2 pl-3">Facilities</span>
            </button>
            <button
                *ngIf="navigationPermission?.admin?.payors?.allowed"
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="payorUrl"
                [routerLinkActive]="['active']"
            >
                <span class="material-icons-outlined sidebar-icon-size position-absolute"> attach_money </span>
                <span class="ml-2 pl-3">Payors</span>
            </button>
            <button
                *ngIf="navigationPermission?.admin?.users?.allowed"
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="usersUrl"
                [routerLinkActive]="['active']"
            >
                <span class="material-icons-outlined sidebar-icon-size position-absolute"> manage_accounts </span>
                <span class="ml-2 pl-3">Users</span>
            </button>
            <button
                *ngIf="environmentName !== 'main' && environmentName !== 'staging'"
                class="body-small-semibold p-4"
                style="padding-top: 19px !important"
                ngbDropdownItem
                [routerLink]="recipientUrl"
                [routerLinkActive]="['active']"
            >
                <span
                    class="material-icons-outlined sidebar-icon-size position-absolute"
                    style="margin-top: -2px !important"
                >
                    manage_accounts
                </span>
                <span class="ml-2 pl-3">Add Recipient</span>
            </button>
            <!-- <button
                *ngIf="AuthService.isSuperAdmin"
                class="body-small-semibold p-4"
                style="padding-top: 19px !important"
                ngbDropdownItem
                [routerLink]="rightsUrl"
                [routerLinkActive]="['active']"
            >
                <span
                    class="material-icons-outlined sidebar-icon-size position-absolute"
                    
                >
                    lock
                </span>
                <span class="ml-2 pl-3">User Rights</span>
            </button> -->
            <!-- <button
                *ngIf="AuthService.isSuperAdmin"
                class="body-small-semibold p-4"
                style="padding-top: 19px !important"
                ngbDropdownItem
                [routerLink]="resourcesUrl"
                [routerLinkActive]="['active']"
            >
                <span
                    class="material-icons-outlined sidebar-icon-size position-absolute"
                    
                >
                    widgets
                </span>
                <span class="ml-2 pl-3">Resources</span>
            </button> -->
            <button
                *ngIf="navigationPermission?.admin?.roles?.allowed"
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="rolesUrl"
                [routerLinkActive]="['active']"
            >
                <span class="material-icons-outlined sidebar-icon-size position-absolute"> group </span>
                <span class="ml-2 pl-3">Roles</span>
            </button>
        </div>
    </li>

    <!-- ========================== -->
    <!-- System Integrations  -->
    <!-- ========================== -->
    <li
        *ngIf="navigationPermission?.systemIntegration?.allowed"
        ngbDropdown
        class="sidebar-item mt-1 position-relative"
        ngbNavItem="integrations"
        [routerLink]="integrationUrl"
        [routerLinkActive]="['active']"
    >
        <span ngbDropdownToggle class="sidebar-item-inner pt-1 pb-1" ngbNavItem="integrations" id="dropdownBasic1">
            <span
                class="icon-integration material-symbols-outlined sidebar-icon-size p-2 icon-circle-nav rounded-circle"
            >
            </span>
            <span class="text mt-1">Integrations</span>
        </span>
        <!-- Uncomment the following code to enable the integrations and if add any other -->
        <!-- <div
            ngbDropdownMenu
            class="sidebar-dropdown ml-5 mt-n5 position-absolute sub-navbar p-0"
            aria-labelledby="dropdownBasic1"
        >
            <button
                *ngIf="navigationPermission?.systemIntegration?.allowed"
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="integrationUrl"
                [routerLinkActive]="['active']"
            >
                <span class="material-symbols-outlined sidebar-icon-size position-absolute">
                    integration_instructions
                </span>
                <span class="ml-2 pl-3">Integration</span>
            </button>
        </div> -->
    </li>

    <!-- ========================== -->
    <!-- Reports  -->
    <!-- ========================== -->
    <li
        *ngIf="navigationPermission?.reports?.allowed"
        class="sidebar-item mt-1 p-4"
        ngbDropdown
        ngbNavItem="reports"
        [class.active]="
            isActive([
                serviceLogUrl,
                billingExportUrl,
                standardBillingExportUrl,
                tripleCheckReportsUrl,
                endOfMonthReportUrl,
                mopReport
            ])
        "
        [routerLinkActiveOptions]="{ exact: true }"
    >
        <span ngbDropdownToggle class="sidebar-item-inner pt-1 pb-1" ngbNavItem="reports" id="dropdownBasic2">
            <span class="icon icon-notepad sidebar-icon-size p-2 icon-circle-nav rounded-circle"> </span>
            <span class="text mt-1">{{ 'appTranslation.left_nav.reports' | translate | titlecase }}</span>
        </span>
        <div
            ngbDropdownMenu
            class="sidebar-dropdown ml-5 mt-n5 position-absolute sub-navbar p-0"
            aria-labelledby="dropdownBasic2"
        >
            <button
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="serviceLogUrl"
                [routerLinkActive]="['active']"
                *ngIf="navigationPermission?.reports?.serviceLog?.allowed"
            >
                <span class="material-symbols-outlined sidebar-icon-size position-absolute"> inbox_customize </span>
                <span class="ml-2 pl-3">Service Log</span>
            </button>
            <button
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="billingExportUrl"
                [routerLinkActive]="['active']"
                *ngIf="navigationPermission?.reports?.billingReport?.allowed"
            >
                <span class="material-symbols-outlined sidebar-icon-size position-absolute"> export_notes </span>
                <span class="ml-2 pl-3">Billing Export</span>
            </button>
            <button
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="standardBillingExportUrl"
                [routerLinkActive]="['active']"
                *ngIf="navigationPermission?.reports?.billingReport?.allowed"
            >
                <span class="material-symbols-outlined sidebar-icon-size position-absolute"> export_notes </span>
                <span class="ml-2 pl-3">Standard Billing Export</span>
            </button>
            <button
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="tripleCheckReportsUrl"
                [routerLinkActive]="['active']"
                *ngIf="navigationPermission?.reports?.tripleReport?.allowed"
            >
                <span class="material-symbols-outlined sidebar-icon-size position-absolute"> playlist_add_check </span>
                <span class="ml-2 pl-3">Triple Check Report</span>
            </button>
            <button
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="endOfMonthReportUrl"
                [routerLinkActive]="['active']"
                *ngIf="navigationPermission?.reports?.eomReport?.allowed"
            >
                <span class="material-symbols-outlined sidebar-icon-size position-absolute"> export_notes </span>
                <span class="ml-2 pl-3">EOM Report</span>
            </button>
            <button
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="mopReport"
                [routerLinkActive]="['active']"
                *ngIf="navigationPermission?.reports?.mbpReport?.allowed"
            >
                <span class="material-symbols-outlined sidebar-icon-size position-absolute"> export_notes </span>
                <span class="ml-2 pl-3">Minutes By Payor</span>
            </button>
        </div>
    </li>

    <!-- ========================== -->
    <!-- Billings  -->
    <!-- ========================== -->
    <li
        *ngIf="navigationPermission?.billing?.allowed && environmentName !== 'main' && environmentName !== 'staging'"
        class="sidebar-item mt-1 p-4"
        ngbDropdown
        (click)="(false)"
        ngbNavItem="billing"
        [class.active]="
            isActive([
                eomBillingUrl,
                invoicingUrl
            ])
        "
        [routerLinkActiveOptions]="{ exact: true }"
    >
        <span ngbDropdownToggle class="sidebar-item-inner pt-1 pb-1" ngbNavItem="billing">
            <span class="material-symbols-outlined sidebar-icon-size p-2 icon-circle-nav rounded-circle">
                attach_money
            </span>
            <span class="text mt-2">{{ 'appTranslation.left_nav.billing' | translate | titlecase }}</span>
        </span>
        <div
            ngbDropdownMenu
            class="sidebar-dropdown ml-5 mt-n5 position-absolute sub-navbar p-0"
            aria-labelledby="dropdownBasic2"
            style="margin-top: -250px important; z-index: 1050 !important"
        >
            <button
                class="body-small-semibold p-4"
                style="padding-top: 19px !important"
                ngbDropdownItem
                [routerLink]="eomBillingUrl"
                [routerLinkActive]="['active']"
                *ngIf="navigationPermission?.billing?.eom?.allowed"
            >
                <span
                    class="material-symbols-outlined sidebar-icon-size position-absolute"
                    style="margin-top: -2px !important"
                >
                    inbox_customize
                </span>
                <span class="ml-2 pl-3">EOM Billing</span>
            </button>
            <button
                class="body-small-semibold p-4 sub-dropdown-item"
                ngbDropdownItem
                [routerLink]="invoicingUrl"
                [routerLinkActive]="['active']"
                *ngIf="navigationPermission?.billing?.invoicing?.allowed"
            >
                <span
                    class="material-symbols-outlined sidebar-icon-size position-absolute"
                    style="margin-top: -2px !important"
                >
                    export_notes
                </span>
                <span class="ml-2 pl-3">Invoicing</span>
            </button>
        </div>
    </li>
    <!-- colworx-ak start -->
    <ng-container *ngIf="AuthService.onlyPhysicianORPhysicianStaff">
        <li
            *ngIf="
                (navigationPermission?.PocDocument?.allowed && AuthService.isPhysician) ||
                (navigationPermission?.PocDocument?.allowed && AuthService.isPhysicianStaff)
            "
            class="sidebar-item mt-1 p-4"
            ngbNavItem="documents"
            [routerLink]="AuthService.isPhysician ? physicianPocDocUrl : physicianStaffPocDocUrl"
            [routerLinkActive]="['active']"
        >
            <span ngbNavLink class="sidebar-item-inner pt-1 pb-1" ngbNavItem="documents">
                <span class="icon icon-notepad sidebar-icon-size p-2 icon-circle-nav rounded-circle"> </span>
                <span class="text mt-2 text-nowrap">Documents</span>
            </span>
        </li>
        <li
            *ngIf="navigationPermission?.UserManagement?.allowed"
            class="sidebar-item mt-1 p-4"
            ngbNavItem="userMgt"
            [routerLink]="userMgUrl"
            [routerLinkActive]="['active']"
        >
            <span ngbNavLink class="sidebar-item-inner pt-1 pb-1" ngbNavItem="userMgt">
                <span class="icon-settings material-symbols-outlined sidebar-icon-size p-2 icon-circle-nav rounded-circle">
                </span>
                <span class="text mt-2 text-nowrap">User Mgt.</span>
            </span>
        </li>
    </ng-container>
</ul>
