/* eslint-disable spellcheck/spell-checker */
import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';
import { FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-document-reject-coment-modal',
    templateUrl: './document-reject-coment-modal.component.html',
    styleUrls: ['./document-reject-coment-modal.component.scss'],
})
// colowrox-sk: add DocumentRejectCommentModalComponent
export class DocumentRejectCommentModalComponent implements OnInit {
    content: any;
    selectedValue: any;
    reason: any;
    reasonForm = new FormGroup({
        reason: new FormControl(''),
    });

    constructor(@Inject(DIALOG_DATA) private data, private dialogRef: DialogRef) {
        this.content = data;
    }
    ngOnInit(): void {
        // throw new Error('Method not implemented.');
    }

    onClose(data?: any) {
        this.dialogRef.close(data);
    }

    addReason() {
        if (this.selectedValue == 'Other') {
            this.reason = this.reasonForm.value.reason;
        } else {
            this.reason = this.selectedValue;
        }
        this.onClose(this.reason);
    }

    closeAction() {
        this.dialogRef.close();
    }
}
